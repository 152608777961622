/*
** @name: Meu Clínicas - confirmarTelefone
** @author: 
** @date:
** @description: Módulo para confirmação de telefone não validados no cadastro
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards.
*/

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import $ from 'jquery';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppGeneralSettingsPropertyByName } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';


// Import module styles
import './confirmarTelefone.scss'; 


const ConfirmarTelefone = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <ConfirmarTelefoneImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class ConfirmarTelefoneImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            smsLocalizador: null,
            newTokenMessage: null,
            validateError: null,
            validateSuccess: null,
            enableResendToken: false,
            resetFocusInput: true
        };
    }

    _clearMessages = (resetFocus) => {
        this.setState({
            newTokenMessage: null,
            validateError: null,
            enableResendToken: false,
            resetFocusInput: resetFocus ? true : this.state.resetFocusInput
        });
    }

    _focusInputField = () => {
        $("#input-sms-localizador").focus();
        this.setState({ resetFocusInput: false });
    }

    _getFieldClasses = (fieldName) => {
        const field = this.state.form.fields[fieldName];
        let classes = "";

        classes += field.value ? 'has-content' : 'empty';
        classes += field.errorMessage ? ' invalid' : ' valid';

        return classes;
    }

    _handleLocalizadorChange = (value) => {
        const smsLocalizador = (!value ? "" : value.replace(/[^0-9]/g, '')).substr(0, 5);
        this.setState({
            smsLocalizador: smsLocalizador
        });
        this._clearMessages();
    }

    _processarValidacao = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this._setLoading(true);
        this._clearMessages(true);

        const user = this.props.authContext.properties.user;
        usuarioClient.ativarSmsUsuario(
            user.cpf,
            this.state.smsLocalizador,
            (res => {                
                this._setLoading(false);
                this.setState({ 
                    validateSuccess: {
                        header: "Obrigado",
                        message: "Celular confirmado com sucesso."
                    }
                });
            }),
            (err => {
                this._setLoading(false);

                if(err.response && err.response.data) {
                    this.setState({
                        smsLocalizador: null,
                        enableResendToken: true,
                        validateError: { 
                            header: "Atenção",
                            message: err.response.data
                        }
                    }); 
                } else {
                    this.setState({
                        validateError: { 
                            header: "Ops!",
                            message: "Ocorreu um erro processando sua requisição"
                        }
                    });
                }
            })
        );
    }

    _reenviarCodigo = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this._setLoading(true);
        this._clearMessages(true);

        const user = this.props.authContext.properties.user;
        usuarioClient.reenviarSms(
            user.cpf,
            (res => {                
                this._setLoading(false);
                this.setState({
                    newTokenMessage: {
                        header: null,
                        message: "Foi enviado um novo token para o celular cadastrado. Você deve receber em alguns instantes para tentar nova confirmação."
                    }
                });
            }),
            (err => {  
                this._setLoading(false);

                if(err.response && err.response.data) {
                    this.setState({
                        validateError: { 
                            header: "Não está recebendo a mensagem SMS?",
                            message: err.response.data
                        }
                    }); 
                } else {
                    this.setState({
                        enableResendToken: true,
                        validateError: { 
                            header: "Ops!",
                            message: "Ocorreu um erro processando sua requisição"
                        }
                    });
                }
            })
        );
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _validateForm = () => {
        return this.state.smsLocalizador && this.state.smsLocalizador.length === 5;
    }

    componentDidMount() {
        setTimeout(() => this._focusInputField(), 100);
    }

    componentDidUpdate() {
        if(this.state.resetFocusInput) {
            this._focusInputField();
        }
        if(!this.closeTimeoutId && this.state.validateSuccess) {
            this.closeTimeoutId = utils.setAutomaticCardClose(this.props.appControllerContext, APP_SERVICE_LIST.CONFIRMAR_TELEFONE);
            this.props.appControllerContext.methods.doSetEventOnBeforeCloseModule(() => { clearTimeout(this.closeTimeoutId); });
        }
    }

    render() {
        const user = this.props.authContext.properties.user;
        const appName = getAppGeneralSettingsPropertyByName(this.props.appContextConfig, "app-name");

        return(
            <AppCardModuleBasicWrapper moduleName="confirmar-telefone">

                <div className="info-principal">
                    Verifique seu número
                </div>
                    
                <div className="info-adicional">
                    O {appName} enviou um SMS para verificar o seu número de celular.
                    Digite o código do SMS enviado para {user.celular.replace(/(\d{5})(?=-)/g, "*****")}.
                </div>

                <Form className="confirmar-telefone-form">
                    <div className="token-input-wrapper">
                        <div className="float-label-field sms-token">
                            <Input
                                fluid
                                id="input-sms-localizador"
                                name="sms-localizador"
                                disabled={this.state.validateSuccess ? true : false}
                                className=""
                                autoComplete="off"
                                placeholder='_____' 
                                value={this.state.smsLocalizador ? this.state.smsLocalizador : ""}  
                                onChange={(e) => this._handleLocalizadorChange(e.target.value)}
                            />
                        </div>
                    </div>

                    { this.state.validateError &&
                    <AppMessageBox 
                        id="msg-sms-error" 
                        className="error" 
                        messageData={this.state.validateError} />
                    }

                    { this.state.newTokenMessage &&
                    <AppMessageBox 
                        id="msg-new-token-success" 
                        className="information"
                        messageData={this.state.newTokenMessage} />
                    }

                    { this.state.validateSuccess &&
                    <AppMessageBox 
                        id="msg-sms-success" 
                        className="information"
                        messageData={this.state.validateSuccess} />
                    }

                    <div className="main-action">
                        { !this.state.validateSuccess &&
                        <>
                            <div>
                                { this.state.enableResendToken &&
                                <button
                                    id="button-reenviar-codigo"
                                    type="button"
                                    className="app-form-button"
                                    onClick={(e) => this._reenviarCodigo(e)}>
                                    Enviar outro código
                                </button>
                                }
                            </div>

                            <div>
                                <button
                                    id="button-validar"
                                    type="default"
                                    className="app-form-button"
                                    disabled={!this._validateForm()}
                                    onClick={(e) => this._processarValidacao(e)}>
                                    OK
                                </button>
                            </div>
                        </>
                        }
                    </div>  

                </Form>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default ConfirmarTelefone;