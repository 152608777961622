/*
** @name: Meu Clínicas - servicesInfo
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2024
** @description: Módulo para renderizar seção de informações dos serviços no dashboard (Refatorado do módulo principal)
*/

import React from 'react';
import Moment from 'react-moment';
import { Accordion } from 'semantic-ui-react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';


const ServicesInfo = (props) => {
    const { buildInfo, onToggle } = props;
    const showGitInfo = process.env.REACT_APP_ENVIRONMENT.toLocaleUpperCase() !== "PRD";
    return(
        <Accordion fluid styled>
        { buildInfo
            .sort((a, b) => (a.order > b.order) ? 1 : -1)
            .map((item, indexGrupo) => {
                if(!item.data) {
                    return null;
                }
                return (
                    <div className={`accordion-item${item.active ? " active" : ""}`} key={item.title}>
                        <Accordion.Title onClick={() => onToggle(item)}>
                            <div className="title-info-wrapper">{item.title}</div>
                            <div className="collapsible-wrapper">
                                <AccordionCollapsible active={item.active} iconVariant={null} />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content>
                            <div className="content-row">
                                <ul>
                                    <li className='service-name'>
                                        <span className='label'>Nome do serviço:</span> 
                                        <span className='text'>{item.data.serviceName}</span>
                                    </li>

                                    <li className='service-version'>
                                        <span className='label'>Versão do build:</span> 
                                        <span className='text'>{item.data.serviceVersion}</span>
                                    </li>

                                    { item.data.apiVersion &&
                                    <li className='api-version'>
                                        <span className='label'>Versão da API:</span> 
                                        <span className='text'>{item.data.apiVersion}</span>
                                    </li>
                                    }

                                    { genesysUtils.typeCheck.isInteger(item.data.buildEpoch) &&
                                    <li className='commit-date-hour'>
                                        <span className='label'>Data/hora do build:</span> 
                                        <span className='text'><Moment format="DD/MM/YYYY HH:mm:ss" >{item.data.buildEpoch}</Moment></span>
                                    </li>
                                    }
                                </ul>

                                { (showGitInfo && item.data.git) && 
                                <div className='git-info'>
                                    <ul>
                                        { item.data.git?.branch &&
                                        <li className='branch'>
                                            <span className='label'>Branch:</span> 
                                            <span className='text'>{item.data.git.branch || "N/D"}</span>
                                        </li> }

                                        <li className='commit-message'>
                                            <span className='label'>Mensagem do commit:</span> 
                                            <span className='text'>{item.data.git.commit.message || "N/D"}</span>
                                        </li>

                                        <li className='commit-author'>
                                            <span className='label'>Autor do commit:</span> 
                                            <span className='text'>{item.data.git.commit.user?.email || "N/D"}</span>
                                        </li>

                                        <li className='commit-id'>
                                            <span className='label'>ID do commit:</span>                                             

                                            { item.data.git.commit.id ?
                                            <a href={`${item.repoUrl}/commit/${item.data.git.commit.id}`} target='_blank' rel="noopener noreferrer" >
                                                {item.data.git.commit.id}
                                            </a>
                                            :
                                            <span className='text'>N/D</span>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                }
                            </div>
                        </Accordion.Content>
                    </div>
                )
            }
        )}
        </Accordion>
    );
}

export default ServicesInfo;