/*
** @name: Meu Clínicas - cartaoPaciente
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2020
** @description: Módulo para gerar cartão do paciente
**
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Maio 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Adicionada opção de download do cartão no formato PDF
*/

import React, { Component } from 'react';
import QRCode  from 'qrcode.react';
import { pascalCase } from '@hcpa-react-components/string-utils';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils, { EMPTY_HREF } from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppServiceSettingsByName } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';


// Import module styles
import './cartaoPaciente.scss'; 


const QR_CODE_DEFAULT_SIZE = 200;

const CartaoPaciente = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <CartaoPacienteImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class CartaoPacienteImplem extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            downloadError: null
        }

        this.afterErrorMsgRef = React.createRef();
    }

    _buildCardQrCode = (value, qrCodeSize) => {
        return(
            <>
                { value &&
                    <QRCode 
                        size={qrCodeSize}
                        includeMargin={false}
                        fgColor={"#000000"}
                        value={value} />
                }
            </>
        )
    }

    _buildFilename = (prontuario) => {
        return `cartao_paciente_${prontuario}`;
    }

    _clearErrors = () => {
        this.setState({
            downloadError: null
        });
    }

    _getPDFCartaoPaciente = () => {
        this._setLoading(true);
        this._clearErrors();

        const user = this.props.authContext.properties.user;
        usuarioClient.buscarPDFCartaoPaciente(
            user.pacCodigo,
            res => { 
                this._setLoading(false);
                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const fileName = this._buildFilename(user.prontuario) + ".pdf";
                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }
            },
            err => {
                this._setLoading(false);
                this.setState({
                    downloadError: {
                        header: "Ops!",
                        message: "Erro obtendo dados do cartão do paciente"
                    }
                });
            }
        ); 
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _scrollToMessageDiv = () => {
        if(this.afterErrorMsgRef.current){
            this.afterErrorMsgRef.current.scrollIntoView({block: "end", behavior: "smooth"});
        }
    }

    Render_PatientCardContent = () => {
        const user = this.props.authContext.properties.user;
        const cardValue = user.numeroCns ? (user.numeroCns + "00000000000000000000000000").slice(0, 26) : null;
        const serviceConfig = getAppServiceSettingsByName(this.props.appContextConfig, APP_SERVICE_LIST.CARTAO_PACIENTE);
        const qrCodeSize = serviceConfig && serviceConfig.qrCodeSize ? serviceConfig.qrCodeSize : QR_CODE_DEFAULT_SIZE;
        const nomePaciente = pascalCase(user.nome);
        const prontuarioPaciente = user.prontuario ? utils.formatProntuario(user.prontuario) : "(não localizado)";
        const cnsPaciente = user.numeroCns ? user.numeroCns : "(não localizado)";
        const qrCode = this._buildCardQrCode(cardValue, qrCodeSize);

        return(
            <div id="cardInfoWrapperId">
                <div className="primary-section">
                    <div className="info-title">CARTÃO DO PACIENTE</div>

                    <div className="info-row">
                        <div className="titulo">Nome completo</div>
                        <div className="descricao">{nomePaciente}</div>
                    </div>

                    <div className="info-row">
                        <div className="titulo">Prontuário</div>
                        <div className="descricao">{prontuarioPaciente}</div>
                    </div>

                    <div className="info-row">
                        <div className="titulo">Cartão SUS</div>
                        <div className="descricao">{cnsPaciente}</div>
                    </div>
                </div>

                <div className="qrcode-section">
                    <div className="qrcode-wrapper">
                        <div className="qrcode-content">
                            {qrCode}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.downloadError) {
            this._scrollToMessageDiv()
        }
    }

    render() {
        return(
            <AppCardModuleBasicWrapper moduleName="cartao-paciente">

                { this.Render_PatientCardContent() }

                <div className="download-patient-card">
                    <a id="link-download-patient-card" 
                        href={EMPTY_HREF}
                        onClick={(e) => this._getPDFCartaoPaciente()}>
                        Baixe aqui a versão para imprimir
                    </a>
                </div>

                { this.state.downloadError &&
                <AppMessageBox 
                    id="msg-download-error" 
                    className="error" 
                    messageData={this.state.downloadError} />
                }

                <div className="padding-rodape" ref={this.afterErrorMsgRef}></div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default CartaoPaciente;