/*
** @name: Meu Clínicas - solicitacaoAgendamentoConsulta
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Junho 2021
** @description: Módulo para criar formulários para solicitação de agendamento de consultas
*/
/*
    Modulos extras:
        AGENDAMENTO_CONSULTAS: requerido
*/


import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import { pascalCase } from '@hcpa-react-components/string-utils';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import utils from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import specialAccessManager from '../../core/specialAccessManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { DropdownField, InputField, TextareaField } from '../../components/fields/formsBuilderCustoms/';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';

import { ActionButtonFormSolic, MensagemErroInicializacaoFormSolic, MensagemSucessoSolic } from './messages.js';

// Import module styles
import './solicitacaoAgendamentoConsulta.scss'; 


const FORM_CONFIG = {
    SOLIC_AGEND: "solicAgendConsulta"
}
const ERROR_CLOSE_TIMEOUT = 7000;

let autoCloseTimeoutId = null;

const SolicitacaoAgendamentoConsulta = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    return(
        <SolicitacaoAgendamentoConsultaImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class SolicitacaoAgendamentoConsultaImplem extends Component {
    constructor(props) {
        super(props);

        const { especialidade, resultadoRegistro, solicitacaoErroAgendamento } = sessionStorageManager.navigation.getCurrentCardModuleParameters() || {};
        this.state = {
            formConfiguration: null,
            fields: null,
            resultadoRegistro: resultadoRegistro ? resultadoRegistro : null,
            erroRegistro: null,
            especialidade: especialidade || {},
            solicitacaoErroAgendamento,
            initializationError: false
        }
    }

    _buildRequest = () => {
        const user = this.props.authContext.properties.user;
        const fields = this.state.fields;
        if(!user || !fields || fields.constructor!==Object) {
            return;
        }

        const request = {};
        request.pacCodigo = user.pacCodigo;
        request.prontuarioPaciente = user.prontuario;
        request.nomePaciente = pascalCase(user.nome);
        request.solicitacaoErroAgendamento = this.state.solicitacaoErroAgendamento;

        const ignoreFiels = ["uselessField"];
        Object.keys(fields).forEach((key) => {
            if(!ignoreFiels.includes(key)) {
                const value = fields[key].value;
                request[key] = (value!=null && value.trim()!=="") ? value : null;
            }
        });

        // Adicionar especialidade
        request.espSeq = this.state.especialidade && this.state.especialidade.espSeq ? this.state.especialidade.espSeq : null;

        return request;
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(genesysUtils.typeCheck.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _configureForm = (config) => {
        const user = this.props.authContext.properties.user || {};

        // Popular campos com dados do cadastro
        if(user.email) {
            setConfigFieldProperty(config, ["emailContato"], "value", user.email);
        }

        const foneRegEx = /^\((\d{2})\)\s([\d-]*)$/;
        if(user.celular && foneRegEx.test(user.celular)) {
            const dddCel = user.celular.replace(foneRegEx, "$1");
            const numeroCel = user.celular.replace(foneRegEx, "$2").replace("-", "");
            setConfigFieldProperty(config, ["dddTelefoneContato"], "value", dddCel);
            setConfigFieldProperty(config, ["numeroTelefoneContato"], "value", numeroCel);
        }
        return (config);
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleFormUpdate = (fields) => {
        this.setState({ fields: fields });
    }

    _initilizeModuloSolicitacao = () => {
        this._setLoading(true);
        const reqConfig = [FORM_CONFIG.SOLIC_AGEND];
        formulariosClient.obterConfiguracoesFormularios(
            reqConfig,
            (res => {              
                this._setLoading(false);
                if(genesysUtils.typeCheck.isObject(res?.data[FORM_CONFIG.SOLIC_AGEND])) {
                    this.setState({ formConfiguration: this._configureForm(res.data[FORM_CONFIG.SOLIC_AGEND]) });
                    return;
                }
                throw new Error("[Configuration-SAC] Invalid configuration response.");
            }),
            (err => { 
                console.error("Error:", err);
                this._setLoading(false);
                this.setState({ initializationError: true });
            })
        );
    }

    _processaErrosFormulario = (erros) => {
        if(erros && erros.constructor === Object) {
            const updateFields = this.state.fields;
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    let message = erros[key];
                    if(!updateFields[key]) {
                        updateFields[key] = {
                            value: null,
                            errorMessage: null
                        }
                    }
                    updateFields[key].errorMessage = message;
                }
            });
            this.setState({ fields: updateFields });
        }
    }

    _registrarSolicitacao = () => {
        this._setLoading(true);
        this._setErroRegistro(null);
        this._clearFieldsError();

        const request = this._buildRequest();
        formulariosClient.solicitacaoAgendamentoConsulta(
            request,
            (res => {              
                const result = res.data;
                this._setLoading(false);
                this.setState({ resultadoRegistro: result });

                if(!result.valid) {
                    const validatorResponse = result.validatorResponse || {};
                    if(validatorResponse.errors && validatorResponse.errors.erroGeral) {
                        this._setErroRegistro(validatorResponse.errors.erroGeral);
                    } else {
                        this._setErroRegistro("Por favor, verifique o correto preenchimento do formulário.");
                    }
                    this._processaErrosFormulario(validatorResponse.errors);
                } else {
                    specialAccessManager.permissions.setSolicAgendConsultaRegistrada(this.props.authContext, request.espSeq);
                    this._updateModules(result);
                }
            }),
            (err => { 
                this._setLoading(false);
                this._setErroRegistro("Ops!, ocorreu um erro registrando sua pesquisa.");
            })
        );
    }

    _setErroRegistro = (msg) => {
        this.setState({ erroRegistro: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _updateModules = (result) => {
        // Atualizar parametros do modulo para incluir o resultado atual
        const parameters = sessionStorageManager.navigation.getCurrentCardModuleParameters() || {};
        parameters.resultadoRegistro = result;
        this.props.appControllerContext.methods.doSetCardModuleParameters(null, parameters);

        // Remove card de agendamento para forçar nova requisiçao do backend
        this.props.appControllerContext.methods.doRemoveCardModules(APP_SERVICE_LIST.AGENDAMENTO_CONSULTAS);
    }

    componentDidMount() {
        const { especialidade } = this.state;
        if(!especialidade || !especialidade.espSeq || !especialidade.nomeEspecialidade) {
            console.error("Especialidade não informada");
            this._handleClose();
        }
        this._initilizeModuloSolicitacao();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.initializationError && !prevState.initializationError) {
            autoCloseTimeoutId = setTimeout(() => this._handleClose(), ERROR_CLOSE_TIMEOUT);
        }
    }

    componentWillUnmount() {
        if(autoCloseTimeoutId) {
            clearTimeout(autoCloseTimeoutId);
        }
    }

    render() {
        const { initializationError, especialidade, resultadoRegistro, erroRegistro, formConfiguration, fields } = this.state;
        const user = this.props.authContext.properties.user;        
        const nomePaciente = pascalCase(user.nome);
        const exibeMensagemSucesso = resultadoRegistro && resultadoRegistro.valid;
        const exibeConteudoModulo = !initializationError && formConfiguration && !exibeMensagemSucesso;

        return(
            <AppCardModuleBasicWrapper moduleName="solicitacao-agendamento-consulta">

                { initializationError && <MensagemErroInicializacaoFormSolic onClick={() => this._handleClose()} /> }

                { exibeMensagemSucesso && <MensagemSucessoSolic onClick={() => this._handleClose()} /> }
                
                { exibeConteudoModulo &&
                <>
                    <div className="user-identification-section">
                        <div className="section-box">
                            <div className="section-title">Identificação do paciente</div>
                            <div className="section-content">
                                <div>
                                    <div className="titulo">Nome completo</div>
                                    <div className="descricao">{nomePaciente}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-section">
                        <div className="section-box">
                            <div className="section-title">Agenda de acompanhamento</div>
                            <div className="section-content">
                                <div className="especialidade">{especialidade.nomeEspecialidade}</div>

                                <div className="instruction">
                                    <AppCustomMessage id="mensagemInstrucaoId" elemType="html" messageId="solicitacao-agendamento-consulta_mensagem-instrucao" className="instruction-message" />
                                    <div className="instruction-required">*Obrigatório</div>
                                </div>
                                
                                <div className="form-wrapper">
                                    <Form name="formMain">
                                        <FormBuilder 
                                            blockFieldUpdate={false}
                                            disableClearErrorOnFieldChange={false}
                                            config={formConfiguration}
                                            fields={fields}
                                            page={0}
                                            className="form-content" 
                                            onChange={this._handleFormUpdate}
                                            overrideFieldRender={{
                                                'dropdown': DropdownField,
                                                'input': InputField,
                                                'textarea': TextareaField
                                            }}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    { erroRegistro &&
                    <AppMessageBox
                        id="msg-error"
                        className="error"
                        messageData={{ "message": erroRegistro }} />
                    }

                    <ActionButtonFormSolic id="sacBtnEnviar" text="Enviar" onClick={() => this._registrarSolicitacao()} />
                </>
                }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default SolicitacaoAgendamentoConsulta;