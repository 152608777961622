/*
** @name: Meu Clínicas - validadorDocumentos
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Julho 2020
** @description: Módulo para os serviços externos (páginas fora do fluxo normal da aplicação)
** para validação de documentos
**
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação
** @update: Novembro 2022
** @description: Reafatorado para externalizar componentes
** @update: Fevereiro 2023
** @description: Adicionado confirmação para validação (data nascimento)
** @update: Maio 2024
** @description: Refatorado para validar outros tipos de documentos
*/

import React, { Component } from 'react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';

import DadosValidacao from './dadosValidacao.js';
import LaudosAtestadosValidado from './laudosAtestadosValidado.js';
import ReceitaValidada from './receitaValidada.js';

import receitasClient from '../../apiClients/receitas/receitasClient.js';
import registrosMedicosClient from '../../apiClients/registrosMedicos/registrosMedicosClient.js';


// Import module styles
import './scss/validadorDocumentos.scss';


const STEP = {
    MAIN: 'main',
    VALIDACAO: 'validacao'
}
const TIPO_DOCUMENTO = {
    RECEITA: "receita",
    LAUDOS_ATESTADOS: "laudos_atestados"
}
const PREFIX = {
    LAUDOS_ATESTADOS: "LA"
}
const SERVICE_PATH = '/servicos/validar/';


const ValidadorDocumentos = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <ValidadorDocumentosImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class ValidadorDocumentosImplem extends Component {

    constructor(props) {
        super(props);

        const param = this._extractPageInfo(this.props.location.pathname);
        this.state = { 
            currentStep: STEP.MAIN,
            token: param,
            tokenError: false,
            documentoValidado: false,
            resultadoValidacao: null,
            tipoDocumentoValidado: null,
            fieldsDispensacao: null,
            erroDispensacao: null,
            resultadoDispensacao: null,
            resultadoDownloadPDF: {
                error: false,
                message: ''
            }
        }
    }

    _disableFieldsDispensacao =() => {
        const updFields = this.state.fieldsDispensacao;
        Object.keys(updFields || {}).forEach(fn => updFields[fn].disabled = true);
        this.setState( {
            fieldsDispensacao: updFields
        });
    }

    _dispensarReceita = fields => {
        const resultValid = this.state.resultadoValidacao;
        if(!resultValid || !resultValid.receita) {
            this.setState({
                currentStep: STEP.MAIN
            });
            return;
        }

        this._setLoading(true);
        this.setState({
            erroDispensacao: null,
            resultadoDispensacao: null
        });

        const receita = resultValid.receita;
        const requestData = {
            localizador: receita.grupos[0].localizador,
            tokenValidacao: receita.grupos[0].tokenValidacao,
            chaveAutenticacao: resultValid.chaveAutenticacao,
            validadaEm: resultValid.validadaEm,

            anotacaoDispensacao: fields['anotacaoDispensacao'].value,
            cnpjFarmacia: fields['cnpjFarmacia'].value ? fields['cnpjFarmacia'].value.replace(/\D/g, '') : null,
            nomeFarmacia: fields['nomeFarmacia'].value,
            nomeFarmaceutico: fields['nomeFarmaceutico'].value,
            ufConselho: fields['ufConselho'].value,
            crf: fields['crf'].value
        };

        receitasClient.registrarDispensacao(
            requestData,
            (res => {    
                this._setLoading(false);

                this._processaResultadoDispensacao(res.data);
            }),
            (err => {                      
                this._setLoading(false);

                this._updateGeneralErrors(err.response);
            })
        );
    }

    _extractPageInfo = (currentPath) => {  
        const re = new RegExp("^" + SERVICE_PATH);
        const parameter = currentPath.replace(re, '').replace(/\/$/, '');

        // Validar identificador
        if(this._isValidToken(parameter)) {
            return parameter;
        } else {
            return "";
        }
    }

    _getCleanFormDispensacao = () => {
        return({
            fields: null
        });
    }

    _getPDFLaudosAtestados = (laudosAtestados) => {
        if(!laudosAtestados) {
            return;
        }

        this._setLoading(true);
        this._setResultadoDownloadPDF(false, '');

        const resultValid = this.state.resultadoValidacao;
        const requestData = {
            tokenValidacao: laudosAtestados.tokenValidacao,
            chaveAutenticacao: resultValid.chaveAutenticacao,
            validadaEm: resultValid.validadaEm,
        };

        registrosMedicosClient.buscarPDFLaudosAtestadosValidado(
            requestData,
            (res => {    
                this._setLoading(false);
                if(res.data) {
                    const base64 = res.data.pdfBase64;
                    const fileName = `laudo-atestado_${laudosAtestados.conNumero}_${laudosAtestados.atestadoSigla}_ca${resultValid.chaveAutenticacao}.pdf`;
                    genesysUtils.general.automaticDownloadData(base64, fileName);

                    this.setState({});
                } else {
                    this._setResultadoDownloadPDF(true, 'Ops! Retorno vazio para PDF da receita.');
                }
            }),
            (err => {                      
                this._setLoading(false);
                this._setResultadoDownloadPDF(true, 'Ops! Ocorreu um erro obtendo PDF da receita.');
            })
        );
    }

    _getPDFReceita = (receita) => {
        if(!receita) {
            return;
        }

        this._setLoading(true);
        this._setResultadoDownloadPDF(false, '');

        const grupo = receita.grupos[0];
        const resultValid = this.state.resultadoValidacao;
        const requestData = {
            localizador: grupo.localizador,
            tokenValidacao: grupo.tokenValidacao,
            chaveAutenticacao: resultValid.chaveAutenticacao,
            validadaEm: resultValid.validadaEm,
            forceShowLink: false
        };

        receitasClient.buscarPDFReceitaDispensada(
            requestData,
            (res => {    
                this._setLoading(false);

                if(res.data) {
                    const base64 = res.data.pdfBase64;
                    const fileName = `receita_${receita.idReceita}_${grupo.grupoId}_p${grupo.periodoVisualizacao}.pdf`;
                    genesysUtils.general.automaticDownloadData(base64, fileName);

                    this.setState(this.state);
                } else {
                    this._setResultadoDownloadPDF(true, 'Ops! Retorno vazio para PDF da receita.');
                }
            }),
            (err => {                      
                this._setLoading(false);
                this._setResultadoDownloadPDF(true, 'Ops! Ocorreu um erro obtendo PDF da receita.');
            })
        );
    }

    _handleDispensarReceita = fields => {
        this.setState({
            fieldsDispensacao: fields
        });
        this._dispensarReceita(fields);
    }

    _handleFecharValidacao = () => {
        this.setState({
            currentStep: STEP.MAIN,
            fieldsDispensacao: null,
            documentoValidado: false,
            resultadoDispensacao: null
        });
    }

    _handlePrint = () => {
        window.print();
        return true;
    }

    _handleTokenError = err => {
        this.setState({
            tokenError: err ? true : false,
            documentoValidado: false,
            resultadoValidacao: null,
            tipoDocumentoValidado: null
        });
    }

    _isValidToken = (token) => {
        return /^[a-zA-Z0-9-_=]+$/.test(token); // Base64 charset, trocando '+' por '-' e '/' por '_'
    }

    _processaResultadoDispensacao = (result) => {
        if(result.sucessoDispensacao) {
            const updateResultadoValidacao = this.state.resultadoValidacao;
            updateResultadoValidacao.receita = result.receita;
            this.setState({
                resultadoValidacao: updateResultadoValidacao,
                resultadoDispensacao: result
            });
            this._disableFieldsDispensacao();
        } else {
            const updFields = this.state.fieldsDispensacao;
            result.listDispensacaoError.forEach(item => {
                updFields[item.nomeCampo].errorMessage = item.mensagemErro;
            });

            this.setState({
                fieldsDispensacao: updFields,
                resultadoDispensacao: result
            });
        }
    }

    _setResultadoDownloadPDF = (error, message) => {
        this.setState({
            resultadoDownloadPDF: {
                error: error,
                message: message
            }
        });
    }

    _setFieldFocus = (fname) => {
        if(!fname) {
            return;
        }
        let l_obj = document.getElementsByName(fname);
        if(l_obj.length > 0) {
            let obj = l_obj[0];
            obj.focus();
        }
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _updateGeneralErrors = (response) => {
        const statusCode = response ? response.status : null;
        let errorMessage;
        switch(statusCode) {
            case 409:
                errorMessage = "Ops... receita já expirada.";
                break;
            case 429:
                errorMessage = "Ops... excesso de dispensações para um mesmo período.";
                break;
            default:
                errorMessage = "Ops... ocorreu um erro processando sua solicitação.";
                break;
        }

        this.setState({
            erroDispensacao: errorMessage
        });
    }

    _validarDocumento = (token, dtNasc) => {
        switch(this._verificaTipoDocumento(token)) {
            case TIPO_DOCUMENTO.LAUDOS_ATESTADOS:
                this._validarLaudosAtestados(token, dtNasc);
            break;
            case TIPO_DOCUMENTO.RECEITA:
                this._validarReceita(token, dtNasc);
            break;
            default:
                throw new Error("Invalid document type.");
        }
    }

    _validarLaudosAtestados = (token, dtNasc) => {
        this._setLoading(true);
        this.setState({ documentoValidado: false });

        const payload = {
            tokenLocalizador: token,
            dataNascimento: dtNasc
        }
        registrosMedicosClient.validarLaudosAtestados(
            payload,
            res => {
                this._setLoading(false);
                let newState = (res.data && res.data.valida) ? STEP.VALIDACAO : STEP.MAIN;
                this.setState({ 
                    currentStep: newState,
                    documentoValidado: true,
                    resultadoValidacao: res.data,
                    tipoDocumentoValidado: TIPO_DOCUMENTO.LAUDOS_ATESTADOS,
                    resultadoDispensacao: null
                });
            },
            err => {                      
                this._setLoading(false);
                this.setState({
                    currentStep: STEP.MAIN,
                    documentoValidado: true,
                    resultadoValidacao: null,
                    tipoDocumentoValidado: TIPO_DOCUMENTO.LAUDOS_ATESTADOS
                });
            }
        );

    }

    _validarReceita = (token, dtNasc) => {
        this._setLoading(true);
        this.setState({ documentoValidado: false });

        const payload = {
            tokenLocalizador: token,
            dataNascimento: dtNasc
        }
        receitasClient.validarReceita(
            payload,
            res => {
                this._setLoading(false);
                let newState = (res.data && res.data.valida) ? STEP.VALIDACAO : STEP.MAIN;
                this.setState({ 
                    currentStep: newState,
                    documentoValidado: true,
                    resultadoValidacao: res.data,
                    tipoDocumentoValidado: TIPO_DOCUMENTO.RECEITA,
                    resultadoDispensacao: null
                });
            },
            err => {                      
                this._setLoading(false);
                this.setState({
                    currentStep: STEP.MAIN,
                    documentoValidado: true,
                    resultadoValidacao: null,
                    tipoDocumentoValidado: TIPO_DOCUMENTO.RECEITA
                });
            }
        );
    }

    _verificaTipoDocumento = (token) => {
        if(token && token.length === 26 && token.substr(0, 2) === PREFIX.LAUDOS_ATESTADOS) {
            return TIPO_DOCUMENTO.LAUDOS_ATESTADOS;
        }
        return TIPO_DOCUMENTO.RECEITA;        
    }

    render() {
        const { currentStep, tipoDocumentoValidado } = this.state;
        return(
            <div className="documento-validador-wrapper">
                <AppExtraDocumentHead subTitle="Validação de Receita" robots="noindex,nofollow" />

                { currentStep===STEP.MAIN &&
                    <DadosValidacao
                        tokenValue={this.state.token}
                        tokenError={this.state.tokenError}
                        documentoValidado={this.state.documentoValidado}
                        resultadoValidacao={this.state.resultadoValidacao}
                        exibeConfirmacaoValidacao={this.state.exibeConfirmacaoValidacao} 
                        isValidToken={val => this._isValidToken(val)}
                        onFieldFocus={this._setFieldFocus}
                        onValidar={this._validarDocumento}
                        onTokenError={this._handleTokenError}
                    />
                }

                { (currentStep===STEP.VALIDACAO && tipoDocumentoValidado===TIPO_DOCUMENTO.LAUDOS_ATESTADOS) &&
                <LaudosAtestadosValidado
                    resultadoValidacao={this.state.resultadoValidacao}
                    resultadoDownloadPDF={this.state.resultadoDownloadPDF}
                    onClearDownloadError={() => this._setResultadoDownloadPDF(false, '')}
                    onClose={this._handleFecharValidacao}
                    onObterPDF={this._getPDFLaudosAtestados}
                />
                }

                { (currentStep===STEP.VALIDACAO && tipoDocumentoValidado===TIPO_DOCUMENTO.RECEITA) &&
                <ReceitaValidada
                    fieldsData={this.state.fieldsDispensacao}
                    erroDispensacao={this.state.erroDispensacao}
                    resultadoDispensacao={this.state.resultadoDispensacao}
                    resultadoValidacao={this.state.resultadoValidacao}
                    resultadoDownloadPDF={this.state.resultadoDownloadPDF}
                    onClearDownloadError={() => this._setResultadoDownloadPDF(false, '')}
                    onClose={this._handleFecharValidacao}
                    onFieldFocus={this._setFieldFocus}
                    onObterPDF={this._getPDFReceita}
                    onRegistrarDispensacao={this._handleDispensarReceita}
                />
                }
            </div>
        );
    }    

}

export default ValidadorDocumentos;