/*
** @name: Meu Clínicas - faqManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2022
** @description: Módulo para gerenciamento das perguntas/respostas do FAQ
** 
*/

import React, { Component } from 'react';
import FormBuilder from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import { emptyToNull } from '@hcpa-react-components/string-utils';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import ExternalServicesSessionLifetime from '../../components/general/externalServicesSessionLifetime/externalServicesSessionLifetime.js';
import { InputField } from '../../components/fields/formsBuilderCustoms';

import FaqEditGrid from './faqEditGrid.js';
import FaqEditModal from './faqEditModal.js';

import loginClient, { ADMIN_SERVICE_LIST } from '../../apiClients/login/loginClient.js';
import wikiClient from '../../apiClients/wiki/wikiClient.js';


// Import module styles
import './scss/faqManager.scss';

// Import form configuration
import formConfigLogin from './loginForm.json';


const CREDENTIAL_EXPIRING_MS = 1200000;
const SESSION_EXPIRATION_WARNING_SEC = 300;
const FIELD_TITULO_MIN_SIZE = 15;
const FIELD_RESPOSTA_MIN_SIZE = 20;
const ERROR_MESSAGE_CREDENTIAL = "Ops!, ocorreu um erro validando suas credenciais.";

const STEP_LOGIN = 'login';
const STEP_EDIT = 'edit';


const FaqManager = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <FaqManagerImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class FaqManagerImplem extends Component {

    constructor(props) {
        super(props);

        this.state = this._initialState();

    }

    _buildItemFromFormEdit = (removeControlProperties) => {
        const { faqEditItem } = this.state;
        const item = {};
        if(faqEditItem && genesysUtils.typeCheck.isObject(faqEditItem.data)) {
            item.active = faqEditItem.data.active;
            item.id = faqEditItem.data.id;
            item.ordem = faqEditItem.data.ordem;
            item.criadoEm = emptyToNull(faqEditItem.data.criadoEm);
            item.atualizadoEm = emptyToNull(faqEditItem.data.atualizadoEm);

            item.hideFromList = faqEditItem.control.fields.hideFromList.value ? true : false;
            item.questionId = emptyToNull(faqEditItem.control.fields.questionId.value);
            item.titulo = faqEditItem.control.fields.titulo.value;
            item.resposta = faqEditItem.control.fields.resposta.value;
            item.tags = faqEditItem.control.fields.tags.value.trim().split(";").map(tag => tag.trim()).filter(tag => tag);

            item.imagens = emptyToNull(faqEditItem.data.imagens);

            if(removeControlProperties) {
                delete item.active;
                delete item.imagesChanged;
            }
        }
        return item;
    }

    _carregarDados = () => {
        this._handleSessionRefresh();
        this._getFaqItems();
    }

    _clearFormEditErros = () => {
        const updEditItem = this.state.faqEditItem;
        if(updEditItem) {
            Object.keys(updEditItem.control.fields).forEach((key) => {
                if(genesysUtils.typeCheck.isObject(updEditItem.control.fields[key])) {
                    updEditItem.control.fields[key].errorMessage = null;
                }
            });
        }

        this.setState({ faqEditItem: updEditItem });
    }

    _deleteItem = (item) => {
        // Obter credenciais atuais
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        // Obter token de servico e deleta item
        this._setLoading(true);
        this._getServiceTokenAsync(username, password)
            .then(credentials => {
                const { jwtServiceToken, fingerprint } = credentials;

                // Delete faq record
                wikiClient.userFaqDelete(
                    jwtServiceToken,
                    fingerprint,
                    item.id,
                    res => {
                        this._setLoading(false);
                        // show success message and reload items
                        const onClose = () => this.setState({ confirmationData: null }, () => this._carregarDados());
                        this.setState({
                            confirmationData: {
                                hideCancelButton: true,
                                title: "Sucesso",
                                message: "O registro foi deletado com sucesso.",
                                onConfirm: onClose,
                                onCancel: onClose
                            }
                        });
                    }, 
                    err => {
                        this._setLoading(false);
                        if(err.response.status===403) {
                            this._goToLoginWithErrorMessage(ERROR_MESSAGE_CREDENTIAL);
                            return;
                        } 
                        const errMessage = err.response.status === 410 ? "Ops!, registro não localizado." : "Ops!, ocorreu um erro processando sua solicitação.";
                        this._setErroProcessamento(errMessage);
                    }
                );
            })
            .catch(err => {
                this._setLoading(false);
                if(err===false) {
                    this._goToLoginWithErrorMessage(this.state.erroProcessamento);
                }
            });
    }

    _focusFormEditField = () => {
        const { currentStep, loginFocus, faqEditItem } = this.state;
        const setFocus = (name) => {
            const l_obj = document.getElementsByName(name);
            if(l_obj.length > 0) {
                let obj = l_obj[0];
                obj.focus();
            }    
        }

        if(currentStep===STEP_LOGIN) {
            if(loginFocus) {
                setFocus(loginFocus);
                this.setState({ loginFocus: false });
            }
        } else if(currentStep===STEP_EDIT) {
            if(faqEditItem && faqEditItem.control.fieldToFocus) {
                setFocus(faqEditItem.control.fieldToFocus);
                faqEditItem.control.fieldToFocus = false;
                this.setState({ faqEditItem: faqEditItem });
            }
        }
    }

    _getCurrentCredentials = () => {
        const { username, password, authenticationTime} = this.state.credentials || {};
        const isCredentialValid = username && password && authenticationTime && ((Date.now() - authenticationTime) <= CREDENTIAL_EXPIRING_MS);
        if(!isCredentialValid) {
            this.setState(this._initialState());
            return [];
        }
        return [username, password];
    }

    _getFaqItems = (listTags) => {
        // Buscar userFaq
        this._setLoading(true);
        this._setErroProcessamento(null);

        wikiClient.userFaqGetAll(
            null, 
            this.state.allTags,
            (listTags ? listTags : this.state.listTags),
            (res => {              
                this._setLoading(false);
                this.setState({
                    faqItems: res.data.listUserFaq
                });
            }),
            (err => { 
                this._setLoading(false);
                const newState = this._initialState();
                newState.loginFields = { ...this.state.loginFields };
                this.setState(newState, () => this._setErroProcessamento("Ops!, ocorreu um erro obtendo lista de perguntas."));
            })
        );
    }

    async _getServiceTokenAsync(username, password) {
        return new Promise(async (resolve, reject) => {
            loginClient.asyncServiceToken(ADMIN_SERVICE_LIST.CONTENT_ADMIN, username, password)
            .then(res => {
                const result = res.data;
                if(!result.jwtServiceToken) {
                    this._setErroProcessamento(result.errorMessage ? result.errorMessage : ERROR_MESSAGE_CREDENTIAL);
                    reject(false);
                }

                const now = Date.now();
                const newCredentials = {
                    jwtServiceToken: result.jwtServiceToken,
                    fingerprint: result.fingerprint,
                    username: username,
                    password: password,
                    authenticationTime: now,
                    keepAliveTime: now
                }

                this.setState({ credentials: newCredentials }, () => resolve(newCredentials));
            })
            .catch(err => {
                this._setErroProcessamento(ERROR_MESSAGE_CREDENTIAL);
                reject(err);
            })
        });
    }

    _goToLoginWithErrorMessage = (message) => {
        const newState = this._initialState();
        newState.erroProcessamento = message;
        this.setState(newState);
    }

    _handleFormEditChange = ({name, value}) => {
        const updEditItem = this.state.faqEditItem;
        const fieldRegex = updEditItem.control.fields[name].regex;
        if (value && fieldRegex && !fieldRegex.test(value)) {
            return;
        }

        updEditItem.control.fields[name].errorMessage = null;
        updEditItem.control.fields[name].value = value;
        updEditItem.control.fieldToFocus = false;
        this.setState({ faqEditItem: updEditItem });
    }

    _handleFormEditSave = () => {
        const editedItem = this._buildItemFromFormEdit(true);
        const isReadyToSave = this._validadeFormToSave(editedItem);
        if(!isReadyToSave) {
            return;
        }
        this._clearFormEditErros();

        // Obter credenciais atuais
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        // Obter token de servico e salvar item
        this._setLoading(true);
        this._getServiceTokenAsync(username, password)
            .then(credentials => {
                const { jwtServiceToken, fingerprint } = credentials;

                // Save faq record
                wikiClient.userFaqSave(
                    jwtServiceToken,
                    fingerprint,
                    editedItem,
                    res => {
                        this._setLoading(false);
                        const result = res.data;
                        if(result.sucesso) {
                            if(!result.item) {
                                const newState = this._initialState();
                                newState.erroProcessamento = "Ops, erro fatal - Retorno inválido para requisição";
                                this.setState(newState);
                                return;
                            }

                            this._setErroProcessamento(null);

                            // show success message and update edit item to reflet changes
                            const confirmationData = {
                                hideCancelButton: true,
                                title: "Sucesso",
                            }
                            let onClose, onStateReady;
                            if(editedItem.id) { // update
                                onClose = () => this.setState({ confirmationData: null });
                                onStateReady = () => this._handleItemEditShow(result.item, false);
                                confirmationData.message = "As alterações foram salvas com sucesso.";
                            } else { // insert
                                onClose = () => { this.setState({ confirmationData: null }); this._handleItemEditClose(false, false); };
                                onStateReady = () => {};
                                confirmationData.message = "Novo item incluido com sucesso.";
                            }
                            confirmationData.onConfirm = onClose;
                            confirmationData.onCancel = onClose;
                            this.setState({ confirmationData }, onStateReady);
                        } else {
                            const validatorResponse = result.validatorResponse || {};
                            this._setErroProcessamento(validatorResponse.errors && validatorResponse.errors.erroGeral ?
                                validatorResponse.errors.erroGeral :
                                "Por favor, verifique o correto preenchimento do formulário."
                            );
                            this._processaErrosFormulario(validatorResponse.errors);
                        }

                    }, 
                    err => {
                        this._setLoading(false);
                        this._setErroProcessamento("Ops!, ocorreu um erro processando sua solicitação.");
                    }
                );
            })
            .catch(err => {
                this._setLoading(false);
                if(err===false) {
                    this._goToLoginWithErrorMessage(this.state.faqEditItem.control.erroEdicao);
                }
            });
    }
    
    _handleLoginFormUpdate = (fields) => {
        this.setState({ loginFields: fields });
    }

    _handleItemDelete = (item, changes) => {
        const doCancel = () => this.setState({ confirmationData: null });
        const doDelete = () => this._deleteItem(item);
        this.setState({
            confirmationData: {
                hideCancelButton: false,
                title: "Atenção",
                message: changes ? 
                        "As alterações de registros no grid não foram salvas e serão perdidas. Confirma a exclusão?" :
                        "Confirma a exclusão do item?",
                onConfirm: () => { doCancel(); doDelete(); },
                onCancel: doCancel
            }
        });
    }

    _handleItemEdit = (item, changes) => {
        const doCancel = () => this.setState({ confirmationData: null });
        const doEdit = () => this._handleItemEditShow(item, false);
        if(changes) {
            this.setState({
                confirmationData: {
                    hideCancelButton: false,
                    title: "Atenção",
                    message: "As alterações de registros no grid não foram salvas e poderão ser perdidas. Confirma?",
                    onConfirm: () => { doCancel(); doEdit(); },
                    onCancel: doCancel
                }
            });
        } else {
            doEdit();
        }
    }

    _handleItemEditClose = (changes, isViewOnly) => {
        const doCancel = () => this.setState({ confirmationData: null });
        const doClose = () => { this.setState({ faqEditItem: null, confirmationData: null }, () => { if(!isViewOnly) { this._carregarDados() } }); };
        if(changes) {
            this.setState({
                confirmationData: {
                    hideCancelButton: false,
                    title: "Atenção",
                    message: "As informações alteradas não foram salvas e serão perdidas. Confirma?",
                    onConfirm: doClose,
                    onCancel: doCancel
                }
            });
        } else {
            doClose();
        }
    }

    _handleItemEditShow = (item, viewOnly) => {
        const formControl = this._initialFormControl();
        const defaultValue = (value, defaultValue) => value ? value : defaultValue;
        item.active = true;
        if(!viewOnly) {
            formControl.fields["questionId"].value = defaultValue(item["questionId"], "");
            formControl.fields["titulo"].value = defaultValue(item["titulo"], "");
            formControl.fields["resposta"].value = defaultValue(item["resposta"], "");
            formControl.fields["tags"].value = genesysUtils.typeCheck.isArray(item["tags"]) ? item["tags"].join(";") : "";
            formControl.fields["hideFromList"].value = item["hideFromList"];
            formControl.fields["imagens"] = item["imagens"];
        }
        this.setState({
            faqEditItem: {
                data: item,
                control: formControl,
                viewOnly
            },
        }, () => this._handleSessionRefresh());
    }

    _handleItemInsert = (position, changes) => {
        const newItem = {
            id: null,
            ordem: position,
            hideFromList: false,
            questionId: null,
            titulo: "",
            resposta: "",
            tags: [],
            imagens: null
        };
        this._handleItemEdit(newItem, changes);
    }

    _handleItemPreview = (item) => {
        this._handleItemEditShow(item, true);
    }

    _handleLogin = () => {
        const { loginFields } = this.state;
        if(!loginFields) {
            return;
        }

        this._setLoading(true);
        this._setErroProcessamento(null);

        const username = loginFields.usuario ? loginFields.usuario.value : null;
        const password = loginFields.senha ? loginFields.senha.value : null;
        this._getServiceTokenAsync(username, password)
            .then(res => {
                this._setLoading(false);
                this.setState({ currentStep: STEP_EDIT }, () => this._carregarDados());
            })
            .catch(err => {
                this._setLoading(false);
            })
    }

    _handleSaveGridChanges = (gridItems) => {
        // Obter credenciais atuais
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        // Obter token de servico e processar alteracoes
        this._setLoading(true);
        this._getServiceTokenAsync(username, password)
            .then(credentials => {
                const { jwtServiceToken, fingerprint } = credentials;
                wikiClient.userFaqBatchUpdate(
                    jwtServiceToken,
                    fingerprint,
                    gridItems,
                    res => {
                        this._setLoading(false);
                        const result = res.data;
                        if(result.sucesso) {
                            this._setErroProcessamento(null);

                            // show success message and reload items
                            const onClose = () => this.setState({ confirmationData: null }, () => this._carregarDados());
                            this.setState({
                                confirmationData: {
                                    hideCancelButton: true,
                                    title: "Sucesso",
                                    message: "As alterações foram salvas com sucesso.",
                                    onConfirm: onClose,
                                    onCancel: onClose
                                }
                            });

                        } else {
                            const validatorResponse = result.validatorResponse || {};
                            this._setErroProcessamento(validatorResponse.errors && validatorResponse.errors.erroGeral ?
                                validatorResponse.errors.erroGeral :
                                "Ops!, ocorreu um erro inesperado como resposta para sua solicitação."
                            );
                        }
        
                    }, 
                    err => {
                        this._setLoading(false);
                        this._setErroProcessamento("Ops!, ocorreu um erro processando sua solicitação.");
                    }
                );
            })
            .catch(err => {
                this._setLoading(false);
                if(err===false) {
                    this._goToLoginWithErrorMessage(this.state.erroProcessamento);
                }
            });
    }

    _handleSessionExpired = () => {
        setTimeout(() => this.setState(this._initialState()), 3000);
    }

    _handleSessionRefresh = () => {
        const updCrendential = this.state.credentials;
        if(updCrendential) {
            updCrendential.keepAliveTime = Date.now();
            this.setState({ credentials: updCrendential });
        }
    }

    _initialFormControl = () => {
        return {
            fieldToFocus: "titulo",
            erroEdicao: null,
            fields: {
                questionId: {
                    regex: /^[0-9a-zA-Z_\- ]{1,40}$/,
                    errorMessage: null,
                    value: ""
                },
                titulo: {
                    regex: /^[ !-/:-@0-9a-zà-ýA-ZÀ-Ý[-_{-~]{1,500}$/,
                    errorMessage: null,
                    value: ""
                },
                resposta: {
                    regex: /^[ !-/:-@0-9a-zà-ýA-ZÀ-Ý[-_{-~]{1,1500}$/,
                    errorMessage: null,
                    value: ""
                },
                tags: {
                    regex: /^[0-9a-zà-ýA-ZÀ-Ý; ]{1,75}$/,
                    errorMessage: null,
                    value: ""
                },
                hideFromList: {
                    errorMessage: null,
                    value: null
                },
                imagens: null
            }
        }
    }

    _initialState = () => {
        return {
            currentStep: STEP_LOGIN,
            loginFields: null,
            loginFocus: 'usuario',
            erroProcessamento: null,
            credentials: null,
            allTags: false,
            faqItems: null,
            faqEditItem: null,
            confirmationData: null
        };
    }

    _processaErrosFormulario = (erros) => {
        if(erros && erros.constructor === Object) {
            const updFaqEditItem = this.state.faqEditItem;
            const updFields = updFaqEditItem && updFaqEditItem.control ? updFaqEditItem.control.fields : null;
            if(updFields) {
                Object.keys(erros).forEach((key) => {
                    if(key!=="erroGeral") {
                        let message = erros[key];
                        if(updFields[key]) {
                            updFields[key].errorMessage = message;
                        }
                    }
                });
                this.setState({
                    fields: updFaqEditItem
                });
            }
        }
    }

    _setErroProcessamento = (msg) => {
        const updFaqEditItem = this.state.faqEditItem;
        if(updFaqEditItem && updFaqEditItem.control) {
            updFaqEditItem.control.erroEdicao = msg;
            this.setState({ faqEditItem: updFaqEditItem });
        } else {
            this.setState({ erroProcessamento: msg });
        }
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _validadeFormToSave = (item) => {
        const updEditItem = this.state.faqEditItem;
        const msgRequired = "Favor informar campo obrigatório";
        const msgInvalidSize = "Campo com muito pouca informação";
        let fieldToFocus = null;
        let validationOk = true;

        // tags
        if(!item.tags || !item.tags.length) {
            fieldToFocus = "tags";
            updEditItem.control.fields["tags"].errorMessage = msgRequired;
        }

        // resposta
        if(!item.resposta || !item.resposta.trim()) {
            fieldToFocus = "resposta";
            updEditItem.control.fields["resposta"].errorMessage = msgRequired;
        }
        if(item.resposta && item.resposta.trim().length < FIELD_RESPOSTA_MIN_SIZE) {
            fieldToFocus = "resposta";
            updEditItem.control.fields["resposta"].errorMessage = msgInvalidSize;
        }

        // titulo
        if(!item.titulo || !item.titulo.trim()) {
            fieldToFocus = "titulo";
            updEditItem.control.fields["titulo"].errorMessage = msgRequired;
        }
        if(item.titulo && item.titulo.trim().length < FIELD_TITULO_MIN_SIZE) {
            fieldToFocus = "titulo";
            updEditItem.control.fields["titulo"].errorMessage = msgInvalidSize;
        }

        // update information
        validationOk = fieldToFocus ? false : true;
        updEditItem.control.erroEdicao = validationOk ? null : "Verifique o correto preenchimento do formulário";
        updEditItem.control.fieldToFocus = fieldToFocus;

        this.setState({
            faqEditItem: updEditItem
        });

        return validationOk;
    }

    componentDidUpdate() {
        if (this.state.currentStep===STEP_EDIT && !this.state.credentials) {
            this.setState(this._initialState());
        } else {
            this._focusFormEditField();
        }
    }

    render() {
        const { currentStep, loginFields, erroProcessamento, credentials, faqItems, faqEditItem, confirmationData } = this.state;
        const loginEnabled = loginFields && loginFields.usuario && loginFields.usuario.value && loginFields.senha && loginFields.senha.value;
        const showError = !faqItems && erroProcessamento ? true : false;

        return (
            <div className="faq-manager-wrapper">
                <AppExtraDocumentHead subTitle="FAQ Manager" robots="noindex,nofollow" />

                <div className="content">
                    <div className="header-card">
                        <AppExternalServiceHeader linkToHome={false}>
                            <h1>FAQ Manager</h1>
                        </AppExternalServiceHeader>
                    </div>

                    { currentStep===STEP_LOGIN &&
                    <div className="login-section">
                        <Form name="formMain">
                            <div className="form-wrapper">
                                <FormBuilder 
                                    config={formConfigLogin}
                                    fields={loginFields}
                                    page={0}
                                    className="form-login" 
                                    onChange={this._handleLoginFormUpdate}
                                    overrideFieldRender={{
                                        'input': InputField
                                    }}
                                />
                            </div>

                            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                                {erroProcessamento}
                            </AppExternalServiceInfoMessage>

                            <div className="action-section">
                                <button type="default" disabled={!loginEnabled} className="btn-login" onClick={e => this._handleLogin(e)}>Login</button>
                            </div>
                        </Form>
                    </div>
                    }

                    { currentStep===STEP_EDIT &&
                    <div className="content-section">

                        <ExternalServicesSessionLifetime
                            sessionStartTime={credentials ? credentials.keepAliveTime : null}
                            onExpire={() => this._handleSessionExpired()}
                            onRefresh={() => this._handleSessionRefresh()}
                            sessionExpireTimeMs={CREDENTIAL_EXPIRING_MS}
                            expirationWarningSec={SESSION_EXPIRATION_WARNING_SEC}
                        />

                        { confirmationData &&
                        <AppConfirmationDialog {...confirmationData} />
                        }

                        <FaqEditModal
                            editItem={faqEditItem}
                            onEditClose={this._handleItemEditClose}
                            onEditSave={this._handleFormEditSave}
                            onFieldChange={this._handleFormEditChange}
                            buildResultItem={this._buildItemFromFormEdit}
                            fieldMinSize={{
                                titulo: FIELD_TITULO_MIN_SIZE,
                                resposta: FIELD_RESPOSTA_MIN_SIZE
                            }}
                        />

                        <div className="section-header-wrapper">
                            <h2>Itens do FAQ:</h2>
                        </div>

                        <FaqEditGrid 
                            listFaq={faqItems}
                            erroProcessamento={erroProcessamento}
                            onItemDelete={this._handleItemDelete}
                            onItemEdit={this._handleItemEdit}
                            onItemInsert={this._handleItemInsert}
                            onItemPreview={this._handleItemPreview}
                            onSave={this._handleSaveGridChanges}
                        />

                        { showError &&
                        <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                            {erroProcessamento}
                        </AppExternalServiceInfoMessage>
                        }

                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default FaqManager;
