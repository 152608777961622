import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";

import { apiUrl } from '@hcpa-react-components/api-url-builder';


const API_PORT = 8084;
const API_SERVICE = 'consultas-service';
const API_VERSION = 'v2';

class ConsultasClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    } 

    buscarListaConsultas(pacCodigo, dtInicial, dtFinal, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };
        if (dtInicial) {
            queryParams.dtInicial = dtInicial;
        }
        
        if (dtFinal) {
            queryParams.dtFinal = dtFinal;
        }   

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/consultas/lista'),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarPDFTicketConsulta(pacCodigo, numero, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
            numero: numero
        };
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/consultas/obter-ticket'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    buscarUltimaConsultaPorEspecialidade(pacCodigo, espSeq, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
            espSeq: espSeq
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/consultas/obter-ultima-por-especialidade'),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarListaEspecialidadeAgendamento(pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/agendamento/especialidades'),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarListaConsultasDisponiveisAgendamento(pacCodigo, espSeq, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
            espSeq: espSeq
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/agendamento/consultas-disponiveis'),
            successCallback,
            errorCallback,
            queryParams);
    }

    obterReservaConsulta(requestData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/agendamento/reserva'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    marcarConsulta(requestData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/agendamento/marcar'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    documentList(pacCodigo, numeroConsulta, successCallback, errorCallback) {
        const queryParams = {
            pacCodigo,
            numeroConsulta
        };
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/document/list'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    documentDelete(requestData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/document/delete'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    documentUpload(requestData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/document/upload'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    documentSaveBach(requestData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/document/save-batch'),
            requestData,
            successCallback,
            errorCallback
        );
    }
}

const consultasClient = new ConsultasClient();
export default consultasClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};