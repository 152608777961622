import requestNoAuth from "../../core/requestNoAuth";

import { apiUrl } from '@hcpa-react-components/api-url-builder';
import { API_PORT, API_SERVICE, API_VERSION } from './loginClient.js';


class ConfigurationClient { 

    async asyncObterParametrosFrontend() {
        return new Promise(async (resolve, reject) => {
            this.obterParametrosFrontend(
                res => { resolve(res); },
                err => { reject(err); }
            );
        });
    }

    obterParametrosCadastro(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/parametros-cadastro'),
            successCallback,
            errorCallback
        );
    }

    obterParametrosFrontend(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/parametros-frontend'),
            successCallback,
            errorCallback
        );
    }
}

const configurationClient = new ConfigurationClient();
export default configurationClient;