import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";

import { apiUrl } from '@hcpa-react-components/api-url-builder';
import { API_PORT, API_SERVICE, API_VERSION } from './loginClient.js';


class UsuarioClient { 

    atualizarUsuarioNoAuth(userData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/atualizar-usuario'),
            userData,
            successCallback,
            errorCallback
        );
    }

    usuarioCadastrar(userData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/criar-usuario'),
            userData,
            successCallback,
            errorCallback
        );
    }

    usuarioValidarDados(userData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/validar-dados-usuario'),
            userData,
            successCallback,
            errorCallback
        );
    }

    verificarAtivacaoCadastro(token, successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/usuario/ativacao'),
            successCallback,
            errorCallback,
            {tkn: token}
        );
    } 

    reenviarEmailTokenViaLink(token, successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/usuario/reenviar-email-token/via-link'),
            successCallback,
            errorCallback,
            {tkn: token}
        );
    } 

    reenviarEmailTokenViaLogin(cpf, successCallback, errorCallback) {        
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/usuario/reenviar-email-token/via-login'),
            successCallback,
            errorCallback,
            {cpf}
        );
    } 

    recuperarSenhaPorEmail(cpfEmail, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/usuario/recuperar-senha-por-email'),
            {cpfEmail},
            successCallback,
            errorCallback
        );
    }
     
    redefinirSenha(novaSenha, token, successCallback, errorCallback) {        
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/usuario/redefinir-senha'),
            {newPassword: novaSenha, token},
            successCallback,
            errorCallback
        );
    }

    enviarTokenSmsByNumber(cpf, numCelular, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/usuario/sms-by-number'),
            { cpf, numCelular },
            successCallback,
            errorCallback
        );
    }

    validarTokenSms(token, tokenId, cpf, numCelular, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/usuario/sms-token-validation'),
            { token, tokenId, cpf, numCelular },
            successCallback,
            errorCallback
        );
    }

    vincularPaciente(localizador, cpf, successCallback, errorCallback) {        
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/usuario/vincular-paciente'),
            successCallback,
            errorCallback,
            {localizador, cpf}
        );
    }

    ativarSmsUsuario(cpf, token, successCallback, errorCallback) {
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/usuario/ativar-sms-usuario'),
            successCallback,
            errorCallback,
            {cpf, token}
        );
    }

    reenviarSms(cpf, successCallback, errorCallback) {        
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/usuario/reenviar-sms'),
            successCallback,
            errorCallback,
            { cpf }
        );
    }

    alterarSenha(senhaAtual, novaSenha, novaSenhaConfirmar, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/usuario/alterar-senha'),
            { senhaAtual, novaSenha, novaSenhaConfirmar },
            successCallback,
            errorCallback
        );
    }

    atualizarUsuario(userData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/usuario/atualizar-usuario'),
            userData,
            successCallback,
            errorCallback
        );
    }

    buscarPDFCartaoPaciente(pacCodigo, successCallback, errorCallback) { 
        const queryParams = {};
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/usuario/cartao-paciente/${pacCodigo}`),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    obterLocalizador(userData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/usuario/obter-localizador'),
            userData,
            successCallback,
            errorCallback
        );
    }

    vincularUsuarios(userData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/usuario/vincular-usuarios'),
            userData,
            successCallback,
            errorCallback
        );
    }
}

const usuarioClient = new UsuarioClient();
export default usuarioClient;