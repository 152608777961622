/*
** @name: Meu Clínicas - specialAccessManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Prove rotinas para para obterção e manipulação de permissões especiais do usuário
**
** @date: Agosto 2021
** @description: Refatoração
**
** @date: Abril 2022
** @description: Criação de novo metodo para setar dados de permissoes
*/

import moment from 'moment';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from './utils.js';
import sessionStorageManager from './sessionStorageManager.js';

import loginClient from '../apiClients/login/loginClient.js';


const REQUIRED_AGE = 18;
const PERMISSIONS = {
    LDPD: {
        backEndServiceName: "login-service",
        appServiceName: "lgpd"
    },
    REAGENDAMENTO_CONSULTA_COVID: {
        backEndServiceName: "formularios-service",
        appServiceName: "solicitacaoReagendamentoConsultaCancelada"
    },
    SOLIC_AGENDAMENTO_CONSULTA: {
        backEndServiceName: "formularios-service",
        appServiceName: "solicitacaoAgendamentoConsulta"
    }
}

class SpecialAccessManager {

    async asyncUserPermissions(pacCodigo) {
        return new Promise(async (resolve, reject) => {
            loginClient.getPermissoes(
                pacCodigo,
                res => { resolve(res); },
                err => { reject(err); }
            );
        });
    }

    user = {
        getAge: (userData) => {
            const user = userData ? userData : sessionStorageManager.auth.getUserData();
            if(genesysUtils.typeCheck.isObject(user)) {
                const dtNascimento = user.dtNascimento;
                const today = moment().startOf('day');
                const age = utils.calculateDateDiff(dtNascimento, today, 'years');
                return age;
            }
            return NaN;
        },

        isComplete: (userData) => {
            const user = userData ? userData : sessionStorageManager.auth.getUserData();
            return (user && user.pacCodigo && user.email && user.celular && user.cpf && user.cpf.indexOf('.') > 0 ? true : false);
        },
    
        isRegistered: (userData) => {
            const user = userData ? userData : sessionStorageManager.auth.getUserData();
            return (user && user.email && user.celular ? true : false);
        },
        
        isUserTermsAccepted: (userData) => {
            const user = userData ? userData : sessionStorageManager.auth.getUserData();
            return (user && user.aceiteTermosSessao);
        },

        isUnderRequiredAge: (userData) => {
            const age = this.user.getAge(userData);
            return (age < REQUIRED_AGE);
        },

        isVerified: (userData) => {
            const user = userData ? userData : sessionStorageManager.auth.getUserData();
            return (this.user.isRegistered(user) && user.dthrConfirmaTelefone && user.emailValidado ? true : false);
        },
    }

    permissions = {
        getData: (permissionConfig) => {
            const user = sessionStorageManager.auth.getUserData();
            return this.permissions.getDataFromUser(user, permissionConfig);
        },

        getDataFromUser: (user, permissionConfig) => {
            const { backEndServiceName, appServiceName } = permissionConfig;
            return (backEndServiceName && appServiceName &&
                user &&
                user.permissoes && 
                user.permissoes[backEndServiceName] && 
                user.permissoes[backEndServiceName][appServiceName]) ? user.permissoes[backEndServiceName][appServiceName].dados : null;
        },
    
        isAllowed: (user, permissionConfig) => {
            const { backEndServiceName, appServiceName } = permissionConfig;
            return (backEndServiceName && appServiceName &&
                user &&
                user.permissoes && 
                user.permissoes[backEndServiceName] && 
                user.permissoes[backEndServiceName][appServiceName]) ? user.permissoes[backEndServiceName][appServiceName].allowed : null;
        },
    
        initDadosPermissao: (user, permissionConfig) => {
            const { backEndServiceName, appServiceName } = permissionConfig;
    
            if(!user || !user.permissoes) {
                return null;
            }
            if(!user.permissoes[backEndServiceName]) {
                user.permissoes[backEndServiceName] = {};
            }
            if(!user.permissoes[backEndServiceName][appServiceName]) {
                user.permissoes[backEndServiceName][appServiceName] = {};
            }
            if(!user.permissoes[backEndServiceName][appServiceName].dados) {
                user.permissoes[backEndServiceName][appServiceName].dados = {};
            }
    
            return user;
        },
    
        setAceiteTermosSessao: (authContext) => {
            if(!authContext)
                return;
    
            const user = authContext.properties.user;
            user.aceiteTermosSessao = true;
    
            // Update Session
            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), user);
            authContext.methods.refresh(); 
        },
    
        setData: (authContext, data) => {
            if(!authContext || !genesysUtils.typeCheck.isObject(data)) {
                return;
            }
            const user = sessionStorageManager.auth.getUserData();
            if(!user) {
                return;
            }

            // Update data and store
            user.permissoes = data || {};
            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), user);
            authContext.methods.refresh();
            return user;
        },

        setDadosConsentimento: (authContext, dados) => {
            if(!authContext || !genesysUtils.typeCheck.isObject(dados)) {
                return;
            }

            const permissionConfig = PERMISSIONS.LDPD;
            const { backEndServiceName, appServiceName } = permissionConfig;
            const user = this.permissions.initDadosPermissao(authContext.properties.user, permissionConfig);
            if(!user) {
                return;
            }

            // Update data and store
            user.permissoes[backEndServiceName][appServiceName].dados.seq = dados.seq;
            user.permissoes[backEndServiceName][appServiceName].dados.pacCodigo = dados.pacCodigo;
            user.permissoes[backEndServiceName][appServiceName].dados.concorda = dados.concorda;
            user.permissoes[backEndServiceName][appServiceName].dados.dtResposta = dados.dtResposta;
            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), user);
            authContext.methods.refresh(); 
        },

        setSolicReagendConsultaCanceladaRegistrada: (authContext) => {
            if(!authContext) {
                return;
            }
    
            const permissionConfig = PERMISSIONS.REAGENDAMENTO_CONSULTA_COVID;
            const { backEndServiceName, appServiceName } = permissionConfig;
            const user = this.permissions.initDadosPermissao(authContext.properties.user, permissionConfig);
            if(!user) {
                return;
            }
    
            // Update permissions and store
            user.permissoes[backEndServiceName][appServiceName].dados.solicitacaoRegistrada = true;
            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), user);
            authContext.methods.refresh(); 
        },
    
        setSolicAgendConsultaRegistrada: (authContext, espSeq) => {
            if(!authContext || !genesysUtils.typeCheck.isInteger(espSeq))
                return;
    
            const permissionConfig = PERMISSIONS.SOLIC_AGENDAMENTO_CONSULTA;
            const { backEndServiceName, appServiceName } = permissionConfig;
            const user = this.permissions.initDadosPermissao(authContext.properties.user, permissionConfig);
            if(!user) {
                return;
            }
    
            if(!user.permissoes[backEndServiceName][appServiceName].dados.solicitacoes) {
                user.permissoes[backEndServiceName][appServiceName].dados.solicitacoes = {};
            }
    
            // Update permissions and store
            user.permissoes[backEndServiceName][appServiceName].dados.solicitacoes[espSeq] = 1;
            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), user);
            authContext.methods.refresh();
        },
    }

}

const specialAccessManager = new SpecialAccessManager();
export default specialAccessManager;
export {
    REQUIRED_AGE,
    PERMISSIONS
}