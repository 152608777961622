/*
** @name: Meu Clínicas - informarLocalizador
** @author: 
** @date:
** @description: Módulo para o paciente informar/vincular o localizador quando não feito no cadastro
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
*/
/*
    Modulos extras:
        OBTER_LOCALIZADOR: opcional
*/

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import $ from 'jquery';
import { useAppConfigContext, AppCustomMessage } from '@hcpa-react-components/app-customization';

import utils, { EMPTY_HREF } from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { hideService } from '../../components/general/appNavigationControls/appNavigationControls.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';


// Import module styles
import './informarLocalizador.scss'; 


const InformarLocalizador = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <InformarLocalizadorImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class InformarLocalizadorImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            localizador: "",
            updateError: null,
            updateSuccess: null,
            resetFocusInput: true
        };
    }

    _focusInputField = () => {
        $("#input-localizador").focus();
        this.setState({ resetFocusInput: false });
    }

    _handleLocalizadorChange = (value) => {
        if(value && !/^([a-zA-Z]|[a-zA-Z][a-zA-Z0-9']{1,7})$/.test(value)) {
            return;
        }

        if (value) {
            value = value.toUpperCase();
        }
        this.setState({
            localizador: value,
            updateError: null
        });
    }

    _handleObterLocalizador = () => {
        this.props.appControllerContext.methods.doAddCardModule(APP_SERVICE_LIST.OBTER_LOCALIZADOR, null, false);
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _updateDadosPaciente = (response) => {
        const fingerprint = this.props.authContext.properties.fingerprint;
        const user = this.props.authContext.properties.user;

        user.nome = response.nome;
        user.nomeMae = response.nomeMae;
        user.naturalidade = response.naturalidade;
        user.pacCodigo = response.pacCodigo;
        user.prontuario = response.prontuario;
        user.numeroCns = response.numeroCns;

        sessionStorageManager.auth.store(response.jwtTokenComDadosPaciente, fingerprint, user);
        this.props.authContext.methods.refresh();
    }

    _processarVinculacao = (e) => {
        e.stopPropagation();
        e.preventDefault(); 

        this._setLoading(true);

        const user = this.props.authContext.properties.user;
        const localizador = this.state.localizador;
        const cpf = user.cpf;
        usuarioClient.vincularPaciente(
            localizador,
            cpf,
            (res => {                
                const response = res.data;

                this._setLoading(false);
                this._updateDadosPaciente(response);
                
                this.setState({ 
                    updateSuccess: {
                        header: "Sucesso",
                        message: "Dados de saúde localizados e associados ao seu cadastro."
                    }
                });
            }),
            (err => {  
                let header = null;
                let message = null;
                let localizador = this.state.localizador;

                this._setLoading(false);
                if (err.response && err.response.data && err.response.data.erro) {
                    header = "Atenção";
                    message = err.response.data.erro;
                    localizador = "";
                } else {
                    header = 'Ops...'
                    message = 'Ocorreu um erro ao processar sua requisição.';
                } 

                this.setState({
                    localizador: localizador,
                    resetFocusInput: true,
                    updateError: { header, message }
                });
            })
        );
    } 

    _validateForm = () => {
        return /^([a-zA-Z][a-zA-Z0-9']{7})$/.test(this.state.localizador);
    }

    componentDidMount() {
        setTimeout(() => this._focusInputField(), 100);
    }

    componentDidUpdate() {
        if(this.state.resetFocusInput) {
            this._focusInputField();
        }
        if(!this.closeTimeoutId && this.state.updateSuccess) {
            this.closeTimeoutId = utils.setAutomaticCardClose(this.props.appControllerContext, APP_SERVICE_LIST.INFORMAR_LOCALIZADOR);
            this.props.appControllerContext.methods.doSetEventOnBeforeCloseModule(() => { clearTimeout(this.closeTimeoutId); });
        }
    }

    render() {
        const { authContext, appContextConfig } = this.props;
        const user = authContext.properties.user;

        return(
            <AppCardModuleBasicWrapper moduleName="informar-localizador">

                <div className="info-principal">
                    Você possui um localizador?
                </div>

                <AppCustomMessage messageId="informar-localizador_mensagem-localizador" className="info-adicional" elemType="div" />

                <Form className="informar-localizador-form">
                    <div className="float-label-field localizador">
                        <Input 
                            fluid
                            id="input-localizador"
                            name="localizador"
                            disabled={this.state.updateSuccess ? true : false}
                            className={this.state.localizador ? 'has-content' : 'empty'}
                            onChange={(e) => this._handleLocalizadorChange(e.target.value)}
                            label="Localizador"
                            labelPosition="right"
                            value={this.state.localizador}
                        />                       
                    </div>

                    { !hideService(user, appContextConfig, APP_SERVICE_LIST.OBTER_LOCALIZADOR) &&
                    <div className="obter-localizador">
                        Se você não possui o seu localizador, <a href={EMPTY_HREF} id="link-obter-localizador" onClick={() => this._handleObterLocalizador()}>clique aqui</a> para obter.
                    </div>
                    }

                    { this.state.updateError &&
                    <AppMessageBox 
                        id="msg-informar-localizador-error" 
                        className="error" 
                        messageData={this.state.updateError} />
                    }

                    { this.state.updateSuccess &&
                    <AppMessageBox 
                        id="msg-informar-localizador-success" 
                        className="information"
                        messageData={this.state.updateSuccess} />
                    }

                    <div className="main-action">
                        { !this.state.updateSuccess &&
                        <button 
                            id="button-informar-localizador"
                            type="default"
                            className="app-form-button"
                            disabled={!this._validateForm()}
                            onClick={(e) => this._processarVinculacao(e)}>
                            Vincular
                        </button>
                        }
                    </div>  
                </Form>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default InformarLocalizador;