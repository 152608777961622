/*
** @name: Meu Clínicas - reagendamentoConsultaCanceladaCovid
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2020
** @description: Módulo para criar formulários para solicitação de reagendamento de consultas canceladas por causa da COVID
**
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizando tratamento do retorno de erro para novo sistema de validacao
*/

import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import { pascalCase } from '@hcpa-react-components/string-utils';
import { useAppConfigContext, AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import specialAccessManager, { PERMISSIONS } from '../../core/specialAccessManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { isAppServiceEnabled } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { DropdownField, InputField, TextareaField } from '../../components/fields/formsBuilderCustoms/';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';

import { ActionButtonFormSolicReagend, MensagemErroInicializacaoFormSolicReagend, MensagemPreenchidoSolicReagend, MensagemSucessoSolicReagend } from './messages.js';


// Import module styles
import './reagendamentoConsultaCanceladaCovid.scss'; 


const FORM_CONFIG = {
    SOLIC_REAGEND: "solicAgendConsultaCanceladaCovid"
}
const ERROR_CLOSE_TIMEOUT = 7000;

let autoCloseTimeoutId = null;

const ReagendamentoConsultaCanceladaCovid = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <ReagendamentoConsultaCanceladaCovidImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class ReagendamentoConsultaCanceladaCovidImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formConfiguration: null,
            fields: null,
            resultadoRegistro: null,
            erroRegistro: null,
            initializationError: false
        }
    }

    _buildRequest = () => {
        const user = this.props.authContext.properties.user;
        const fields = this.state.fields;
        if(!user || !fields || fields.constructor!==Object) {
            return;
        }

        const request = {};
        request.pacCodigo = user.pacCodigo;
        request.prontuarioPaciente = user.prontuario;
        request.nomePaciente = pascalCase(user.nome);

        const ignoreFiels = ["uselessField"];
        Object.keys(fields).forEach((key) => {
            if(!ignoreFiels.includes(key)) {
                const value = fields[key].value;
                request[key] = (value!=null && value.trim()!=="") ? value : null;
            }
        });

        return request;
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(genesysUtils.typeCheck.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _configureForm = (config) => {
        const user = this.props.authContext.properties.user || {};

        // Popular campos com dados do cadastro
        if(user.email)
            setConfigFieldProperty(config, ["emailContato"], "value", user.email);

        const foneRE = /^\((\d{2})\)\s([\d-]*)$/;
        if(user.celular && foneRE.test(user.celular)) {
            const ddd = user.celular.replace(foneRE, "$1");
            const numero = user.celular.replace(foneRE, "$2").replace("-", "");
            setConfigFieldProperty(config, ["dddTelefoneContato"], "value", ddd);
            setConfigFieldProperty(config, ["numeroTelefoneContato"], "value", numero);
        }
        return config;
    }

    _handleClose = () => {
        const { appControllerContext } = this.props;
        appControllerContext.methods.doCardFadeOut();
    }

    _handleFormUpdate = (fields) => {
        this.setState({
            fields: fields 
        });
    }

    _initilizeModuloReagendamento = () => {
        this._setLoading(true);
        const reqConfig = [FORM_CONFIG.SOLIC_REAGEND];
        formulariosClient.obterConfiguracoesFormularios(
            reqConfig,
            (res => {              
                this._setLoading(false);
                if(genesysUtils.typeCheck.isObject(res?.data[FORM_CONFIG.SOLIC_REAGEND])) {
                    this.setState({ formConfiguration: this._configureForm(res.data[FORM_CONFIG.SOLIC_REAGEND]) });
                    return;
                }
                throw new Error("[Configuration-RCCC] Invalid configuration response.");
            }),
            (err => { 
                console.error("Error:", err);
                this._setLoading(false);
                this.setState({
                    initializationError: true
                });
            })
        );
    }

    _processaErrosFormulario = (erros) => {
        if(erros?.constructor === Object) {
            const updateFields = { ...this.state.fields };
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    let message = erros[key];
                    if(!updateFields[key]) {
                        updateFields[key] = { value: null, errorMessage: null };
                    }
                    updateFields[key].errorMessage = message;
                }
            });
            this.setState({
                fields: updateFields
            });
        }
    }

    _registrarSolicitacaoReagend = () => {
        this._setErroRegistro(null);
        this._clearFieldsError();
        this._setLoading(true);

        const request = this._buildRequest();
        formulariosClient.solicitacaoReagendConsultaCanceladaCovid(
            request,
            (res => {              
                const result = res.data;
                this._setLoading(false);
                this.setState({ resultadoRegistro: result });

                if(!result.valid) {
                    const validatorResponse = result.validatorResponse || {};
                    if(validatorResponse.errors && validatorResponse.errors.erroGeral) {
                        this._setErroRegistro(validatorResponse.errors.erroGeral);
                    } else {
                        this._setErroRegistro("Por favor, verifique o correto preenchimento do formulário.");
                    }
                    this._processaErrosFormulario(validatorResponse.errors);
                } else {
                    specialAccessManager.permissions.setSolicReagendConsultaCanceladaRegistrada(this.props.authContext);
                }
            }),
            (err => { 
                this._setLoading(false);
                this._setErroRegistro("Ops!, ocorreu um erro registrando sua pesquisa.");
            })
        );
    }

    _setErroRegistro = (msg) => {
        this.setState({ erroRegistro: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidMount() {
        if(!isAppServiceEnabled(this.props.appContextConfig, APP_SERVICE_LIST.REAGENDAMENTO_CONSULTA_CANCELADA_COVID)) {
            console.error("Module disabled");
            this._handleClose();
        }
        this._initilizeModuloReagendamento();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.initializationError && !prevState.initializationError) {
            autoCloseTimeoutId = setTimeout(() => this._handleClose(), ERROR_CLOSE_TIMEOUT);
        }
    }

    componentWillUnmount() {
        if(autoCloseTimeoutId) {
            clearTimeout(autoCloseTimeoutId);
        }
    }

    render() {
        const { initializationError, resultadoRegistro, erroRegistro, fields, formConfiguration } = this.state;
        const user = this.props.authContext.properties.user;        
        const nomePaciente = pascalCase(user.nome);
        const spData = specialAccessManager.permissions.getDataFromUser(user, PERMISSIONS.REAGENDAMENTO_CONSULTA_COVID);
        const preenchido = spData && spData.solicitacaoRegistrada ? spData.solicitacaoRegistrada : false;
        const exibeMensagemSucesso = resultadoRegistro && resultadoRegistro.valid;
        const exibeConteudoModulo = !initializationError && formConfiguration && !exibeMensagemSucesso && !preenchido;

        return(
            <AppCardModuleBasicWrapper moduleName="reagendamento-consulta-covid">

                { initializationError && <MensagemErroInicializacaoFormSolicReagend onClick={() => this._handleClose()} /> }

                { exibeMensagemSucesso && <MensagemSucessoSolicReagend onClick={() => this._handleClose()} /> }

                { preenchido && <MensagemPreenchidoSolicReagend onClick={() => this._handleClose()} /> }

                { exibeConteudoModulo &&
                <>
                    <div className="user-identification-section">
                        <div className="section-box">
                            <div className="section-title">Identificação do paciente</div>
                            <div className="section-content">
                                <div>
                                    <div className="titulo">Nome completo</div>
                                    <div className="descricao">{nomePaciente}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-section">
                        <div className="section-box">
                            <div className="section-title">Solicitação de Agendamento de Consulta</div>
                            <div className="section-content">
                                <div className="instruction">
                                    <AppCustomMessage id="mensagemInstrucaoId" elemType="html" messageId="reagendamento-consulta-covid_mensagem-instrucao" className="instruction-message" />
                                    <div className="instruction-required">*Obrigatório</div>
                                </div>
                                
                                <div className="form-wrapper">
                                    <Form name="formMain">
                                        <FormBuilder 
                                            blockFieldUpdate={false}
                                            disableClearErrorOnFieldChange={false}
                                            config={formConfiguration}
                                            fields={fields}
                                            page={0}
                                            className="form-content" 
                                            onChange={this._handleFormUpdate}
                                            overrideFieldRender={{
                                                'dropdown': DropdownField,
                                                'input': InputField,
                                                'textarea': TextareaField
                                            }}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    { erroRegistro &&
                    <AppMessageBox
                        id="msg-error"
                        className="error"
                        messageData={{ "message": erroRegistro }} />
                    }

                    <ActionButtonFormSolicReagend id="rcccBtnEnviar" onClick={this._registrarSolicitacaoReagend} text="Enviar" />
                </>
                }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default ReagendamentoConsultaCanceladaCovid;