/*
** @name: Meu Clínicas - tokenUtils
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Rotinas para realizar controle e atualização de token de sessão
*/

import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import logout from '../../../core/logout.js';
import sessionStorageManager from '../../../core/sessionStorageManager.js';

import loginClient from '../../../apiClients/login/loginClient.js';


const ONE_MINUTE_MS = 60000;
const ONE_DAY_MS = 86400000;
const MIN_TIMEOUT_MS = 5000;
const MAX_ACTIVITY_TIME_MS = 5 * ONE_MINUTE_MS;


const extraTokenInfo = () => {
    /* We use sessionStorageManager insted of authContext due to refresh propagation of this
    ** that may return an old 'lifetimeData' when token is updated wich may causes a wrong
    ** time analysis triggers an undesired second token refresh.
    **/
    const lifetimeData = sessionStorageManager.auth.getTokenLifetimeData();
    if(!genesysUtils.typeCheck.isObject(lifetimeData)) {
        return null;
    }
    const now = Date.now();
    const { issuedAt, expireAt, localExpireAt } = lifetimeData;
    const lifetime = (expireAt || 0) - (issuedAt || 0);
    const isExtended = lifetime > ONE_DAY_MS;
    const timeToExpire = localExpireAt - now;
    return { isExtended, lifetime, timeToExpire };
}

const processTokenRefresh = (authContext, isExtended) => {
    if(!authContext.methods.isAuthenticated()) {
        return;
    }

    isExtended = isExtended!==undefined ? isExtended : (extraTokenInfo() || {}).isExtended;
    const fingerprint = authContext.properties.fingerprint;
    const user = authContext.properties.user;
    loginClient.asyncTokenRefresh(user.pacCodigo, isExtended)
        .then(res => {
            const newToken = res.data;
            const rnIntegration = window.rnIntegration;
            if(isExtended && user.appLoginTokenStored && rnIntegration && rnIntegration.isAppRunning()) { // Update app token
                rnIntegration.saveAppLogin(newToken, fingerprint, user.pacCodigo);
            }

            // Update session token
            const issuedAt = genesysUtils.jwt.getIssueTime(newToken);
            user.localClockDifference = Date.now() - (issuedAt ? issuedAt : user.localClockDifference);
            sessionStorageManager.auth.store(newToken, fingerprint, user);
            authContext.methods.refresh();
        })
        .catch(err => {}); // Ignore error
}

const verifyTokenRefresh = (authContext, onSchedule) => {
    const tknInfo = extraTokenInfo();
    if(!genesysUtils.typeCheck.isObject(tknInfo)) {
        return;
    }
    const { isExtended, lifetime, timeToExpire } = tknInfo;
    const isExpired = timeToExpire <= 0;
    if(isExpired) {
        logout(null, authContext, () => {});
        return;
    }

    const now = Date.now();
    const usageTime = sessionStorageManager.usageTrack.getUsageTime();
    const timeSinceLastUsage = now - (usageTime || 0);
    const isActive = timeSinceLastUsage <= MAX_ACTIVITY_TIME_MS;
    const shouldRefresh = !isExpired && (isExtended || isActive) && (timeToExpire <= (lifetime / 2));

    if(shouldRefresh) {
        processTokenRefresh(authContext, isExtended);
    }

    if(!isExtended && !isExpired) {
        const timeout = Math.max(MIN_TIMEOUT_MS, (timeToExpire / 2));
        const timeoutId = setTimeout(() => verifyTokenRefresh(authContext, onSchedule), timeout);
        if(onSchedule) {
            onSchedule(timeoutId);
        }
    }
}

export {
    extraTokenInfo,
    processTokenRefresh,
    verifyTokenRefresh
}