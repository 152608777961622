/*
** @name: Meu Clínicas - pesquisaExperienciaExport
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2020
** @description: Módulo para os serviços externos (páginas fora do fluxo normal da aplicação)
** para exportar pesquisas de experiência do paciente
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards e agrupamento por futuras e históricas
**
** @update: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizando tratamento do retorno de erro para novo sistema de validacao
*/

import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import pt_BR from "date-fns/locale/pt-BR";
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import utils from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import { DateWithDatePicker, InputField, RadioGroupField } from '../../components/fields/formsBuilderCustoms/';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';
import loginClient, { ADMIN_SERVICE_LIST } from '../../apiClients/login/loginClient.js';


// Import module styles
import './pesquisaExperienciaExport.scss';

// Import form configuration
import formConfig from './pesquisaExperienciaExport.json';


const PesquisaExperienciaExport = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <PesquisaExperienciaExportImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class PesquisaExperienciaExportImplem extends Component {

    constructor(props) {
        super(props);

        setConfigFieldProperty(formConfig, ["dataInicial", "dataFinal"], "datePicker.locale", pt_BR);
        this.state = {
            showForm: true,
            fields: null,
            erroExportacao: null,
            resultadoExportacao: null
        }
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(genesysUtils.typeCheck.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _exportarPesquisa = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const fields = this.state.fields;
        if(!fields) {
            return;
        }

        this._setLoading(true);
        this._setErroExportacao(null);
        this._clearFieldsError();
        this.setState({ resultadoExportacao: null });

        const username = fields.usuario ? fields.usuario.value : null;
        const password = fields.senha ? fields.senha.value : null;

        loginClient.asyncServiceToken(ADMIN_SERVICE_LIST.PE_EXPORT, username, password)
            .then(res => {
                const result = res.data;
                if(!result.jwtServiceToken) {
                    this._setLoading(false);
                    this._setErroExportacao(result.errorMessage ? result.errorMessage : "Ops!, ocorreu um erro validando suas credenciais.");
                    return;
                }

                const tipoRegistro = fields.tipoRegistro ? fields.tipoRegistro.value : null;
                const tipoExportacao = fields.tipoExportacao ? fields.tipoExportacao.value : null;
                const versaoFormulario = fields.versaoFormulario ? fields.versaoFormulario.value : null;
                const filtro = {
                    dataInicial: fields.dataInicial ? fields.dataInicial.value : null,
                    dataFinal: fields.dataFinal ? fields.dataFinal.value : null
                }

                formulariosClient.exportarPesquisaExperiencia(
                    result.jwtServiceToken,
                    result.fingerprint,
                    tipoRegistro, 
                    tipoExportacao,
                    versaoFormulario,
                    filtro,
                    (res => {              
                        const result = res.data;
                        this._setLoading(false);
                        this.setState({ resultadoExportacao: result });
                        
                        if(result.sucesso) {
                            this._setErroExportacao(null);
                            this._resetForm();

                            if(result.csvContent) { // Download CSV
                                const data = genesysUtils.base64.encode(result.csvContent);
                                const linkSource = `data:text/plain;charset=Windows-1252;base64,${data}`;
                                const fileName = result.csvFilename ? result.csvFilename : "export.csv";

                                genesysUtils.general.automaticAnchorCreateAndClick(linkSource, fileName, "_blank");
                            }

                        } else {
                            const validatorResponse = result.validatorResponse || {};
                            this._setErroExportacao(validatorResponse.errors && validatorResponse.errors.erroGeral ?
                                validatorResponse.errors.erroGeral :
                                "Por favor, verifique o correto preenchimento do formulário."
                            );
                            this._processaErrosFormulario(validatorResponse.errors);
                        }
                    }),
                    err => {
                        this._setLoading(false);
                        this._setErroExportacao("Ops!, ocorreu um erro obtendo os dados solicitados.");
                    }
                );

            })
            .catch(err => {
                this._setLoading(false);
                this._setErroExportacao("Ops!, ocorreu um erro validando suas credenciais.");
            });
    }

    _handleFormUpdate = (fields) => {
        this.setState({ fields: fields });
    }

    _isButtonEnabled = () => {
        const fields = this.state.fields;
        const buttonEnabled = fields && 
            fields.tipoRegistro && fields.tipoRegistro.value &&
            fields.versaoFormulario && fields.versaoFormulario.value &&
            fields.usuario && fields.usuario.value && fields.senha && fields.senha.value ? true : false;

        return buttonEnabled;
    }

    _processaErrosFormulario = (erros) => {
        if(erros && erros.constructor === Object) {
            const updateFields = this.state.fields;
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    let message = erros[key];
                    if(!updateFields[key]) {
                        updateFields[key] = {
                            value: null,
                            errorMessage: null
                        }
                    }
                    updateFields[key].errorMessage = message;
                }
            });
            this.setState({
                fields: updateFields
            });
        }
    }

    _resetForm = () => {
        const buildRecord = value => { 
            return { value: value, errorMessage: null }
        }
        const updateFields = {
            'tipoRegistro': buildRecord(null),
            'tipoExportacao': buildRecord("NORMAL"),
            'versaoFormulario': buildRecord("V2"),
            'dataInicial': buildRecord(null),
            'dataFinal': buildRecord(null),
            'usuario': buildRecord(null),
            'senha': buildRecord(null),
        }

        this.setState({ showForm: false, fields: updateFields });
    }

    _setErroExportacao = (msg) => {
        this.setState({ erroExportacao: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidUpdate() {
        if(!this.state.showForm) {
            /*    
            **    Com a migração para o React 18.X o dropdown utilizado passou a dar problema com a atualização programatica não
            ** refletir visualmente no campo. Sendo assim é forçada a reconstrução do componente de formulários que usa o dropdown
            ** setando 'showForm', no reset, para false e logo após a renderização para true novamente
            **/
            this.setState({ showForm: true });
        }
    }

    render() {
        const mensagemSucesso = this.state.resultadoExportacao && this.state.resultadoExportacao.sucesso ? 
            "Processo de exportação de " + this.state.resultadoExportacao.totalRegistros + " registros realizado com sucesso." : null;

        return(
            <div className="pesquisa-experiencia-export-wrapper">
                <AppExtraDocumentHead subTitle="Exportação da Pesquisa de Experiência" robots="noindex,nofollow" />

                <div className="content-wrapper">
                    <div className="content-box">
                        <Form name="formMain">
                            <div className="header-card">
                                <AppExternalServiceHeader linkToHome={false}>
                                    <h1><AppCustomMessage elemType="fragment" messageId="_general_admin-header-identificacao" /></h1>
                                    <h2>Pesquisa de Experiência - Exportação</h2>
                                </AppExternalServiceHeader>
                            </div>

                            <div className="primary-card">
                                <div className="section-instruction">
                                    <div className="instruction-message">Informe os dados para exportação e clique em enviar.</div>
                                    <div className="instruction-required">*Obrigatório</div>
                                </div>

                                <div className="form-wrapper">
                                    { this.state.showForm &&
                                    <FormBuilder 
                                        blockFieldUpdate={false}
                                        disableClearErrorOnFieldChange={false}
                                        config={formConfig}
                                        fields={this.state.fields}
                                        page={0}
                                        className="form-export" 
                                        onChange={this._handleFormUpdate}
                                        overrideFieldRender={{
                                            'input': InputField,
                                            'radio-group': RadioGroupField
                                        }}
                                        customComponents={{
                                            dataInicial: DateWithDatePicker,
                                            dataFinal: DateWithDatePicker
                                        }}
                                    /> }
                                </div>
                            </div>

                            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                                {this.state.erroExportacao}
                            </AppExternalServiceInfoMessage>

                            <AppExternalServiceInfoMessage id="msgSuccessID" className="info-success">
                                {mensagemSucesso}
                            </AppExternalServiceInfoMessage>

                            <div className="action-section">
                                <button type="default" className="btn-export" disabled={!this._isButtonEnabled()} onClick={(e) => this._exportarPesquisa(e)}>Exportar</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }    

} export default PesquisaExperienciaExport;