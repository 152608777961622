/*
** @name: Meu Clínicas - userHome
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Módulo para controlar a visualizacao prinpal com área prevista para notificações, com notícias e com
** barra de navegação
**
** @date: Setembro 2021
** @description: Implementado menu na home e barra de navegação aparece baseada na visibilidade do menu da home
*/

import React, { Component } from 'react';
import $ from 'jquery';
import { upperCase, pascalCase } from '@hcpa-react-components/string-utils';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import notificationManager from '../../core/notificationManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import { getAppNavigationSettings, getAppUserHomeSettings } from '../../core/appSpecificConfigHandler.js';

import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import { NavigationBar } from '../../components/general/appNavigationControls/appNavigationControls.js';

import wikiClient from '../../apiClients/wiki/wikiClient.js';

import HomeMenu from './homeMenu.js';
import Notificacoes from './notificacoes.js';
import Novidades from './novidades.js';


// Import module styles
import './scss/userHome.scss'; 

const NEWS_LOCATION_HOME = "USER_HOME";
const SECTIONS = {
    MENU: "menu",
    NOTIFICACAO: "notificacoes",
    NOVIDADES: "novidades"
}

const UserHome = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <UserHomeImplem 
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class UserHomeImplem extends Component {
    constructor(props) {
        super(props);

        const navConfig = getAppNavigationSettings(props.appContextConfig);
        const userHomeCfg = getAppUserHomeSettings(props.appContextConfig) || {};
        this.state = {
            navigationBarOnDesktop: navConfig.navigationBarOnDesktop,
            navigationBarOnMobile: navConfig.navigationBarOnMobile,
            navigationBarHiddenWhenMenuVisible: navConfig.navigationBarHiddenWhenMenuVisible,
            navigationBarHidden: navConfig.navigationBarHiddenWhenMenuVisible ? true : false,
            menu: {
                active: true,
                ...userHomeCfg.menuServicos
            },
            news: {
                active: true,
                list: null,
                ...userHomeCfg.novidades
            },
            notifications: {
                active: true,
                list: null,
                ...userHomeCfg.notificacoes
            },
            pushConflict: null
        };
    }

    _buildSectionOrder = () => {
        const { menu, news, notifications } = this.state;
        const sectionOrder = [];
        sectionOrder.push({ section: SECTIONS.NOTIFICACAO, enabled: notifications.enabled, order: notifications.order });
        sectionOrder.push({ section: SECTIONS.NOVIDADES, enabled: news.enabled, order: news.order });
        sectionOrder.push({ section: SECTIONS.MENU, enabled: menu.enabled, order: menu.order });
        sectionOrder.sort((a, b) => a.order < b.order ? -1 : (a.order > b.order ? 1 : 0));
        return sectionOrder;
    }
    
    _getNews = () => {
        // Buscar notícias
        wikiClient.newsByLocation(
            NEWS_LOCATION_HOME,
            (res => {
                const news = this.state.news;
                news.active = true;
                news.list = res.data.newsList;
                this.setState({ news });
            }),
            (err => { 
                const news = this.state.news;
                news.active = false;
                news.list = [];
                this.setState({ news });
            })
        );
    }

    _getNotifications = () => {
        const { authContext } = this.props;
        const { notifications } = this.state;
        const user = authContext.properties.user;

        // Obter permissões notificações para o usuário (assíncrono)
        if(notifications.enabled) {
            if(user.pacCodigo) {
                notificationManager.asyncUserNotifications(user.pacCodigo)
                    .then(res => { // Update user's notifications
                        notificationManager.notifications.setData(authContext, res);
                    })
                    .catch(err => { // Ignorar erro => fica vazia as notificações
                        console.error('Erro obtendo notificações para o paciente: ' + err.message);
                    });
            } else {
                const notif = {
                    "notices": [
                        {
                            id: "WFE_001",
                            serviceName: "wfe",
                            dtReference: new Date().getTime(),
                            priority: "MEDIUM",
                            description: "Você ainda não informou seu localizador para ter acesso aos seus dados de saúde.",
                            openModule: APP_SERVICE_LIST.INFORMAR_LOCALIZADOR
                        }
                    ]
                };
                notificationManager.notifications.setData(authContext, notif);
            }
        }
    }

    _getScrollElements = () => {
        const containerSelector = ".app-viewport-row.content > .user-home-wrapper";
        const container = $(containerSelector).get(0);
        const content = $(`${containerSelector} > .content-wrapper`).get(0);
        return ({ container, content });
    }

    _handleAccordionClick = (e, item) => {
        item.active = item.active ? false : true;
        this.setState({});
    }
    
    _handleContentScroll = (e) => {
        if(!this.state.navigationBarHiddenWhenMenuVisible) {
            return;
        }

        const navBar = $("#navigationBarWrapperId");
        const menuSection = $("#serviceMnuSectionId");
        if(navBar.length === 1 && menuSection.length === 1) {
            const { container, content } = this._getScrollElements();
            if(container && content) {
                const { navigationBarHidden } = this.state;
                const factorCover = 0.6;
                const factorMinWprHeight = 2.5;
                const wprH = $(container).height();
                const nbH = navBar.height(); // navbar height
                const mnH = $(menuSection).height(); // menu section height
                const mnTop = $(menuSection).offset().top - $(container).offset().top; // menu section top position (relative to container)
                const topCovered = mnTop < 0 ? (Math.abs(mnTop) / mnH) : 0;

                if(topCovered >= factorCover && nbH > 0 && (wprH / nbH) > (factorMinWprHeight - (navigationBarHidden ? 0 : 1))) {
                    this.setState({ navigationBarHidden: false });
                } else {
                    this.setState({ navigationBarHidden: true });
                }
            }
        }
    }

    _handlePushChangeUser = () => {
        // Register push notification device (force add/update)
        this._handlePushCloseAlert();
        const user = this.props.authContext.properties.user;
        notificationManager.registerPushDeviceAsync(user, true)
            .then(res => console.info('Push notification device patient updated'))
            .catch(err => console.error(`Unexpected error registering push device token: ${err ? err.message : ""}`));
    }

    _handlePushCloseAlert = () => {
        notificationManager.notifications.setPushUserConflict(this.props.authContext, null);
    }

    _updateContentArea = () => {
        const { container, content } = this._getScrollElements();
        utils.updateScrollableArea(container, content);
    }

    componentDidMount() {
        if(genesysUtils.deviceCheck.isDesktop()) {
            const { content } = this._getScrollElements();
            this.contentResizeMonitor = genesysUtils.general.startResizeMonitor(content, this._updateContentArea);
        }
        genesysUtils.general.startResizeMonitor(null, this._handleContentScroll);

        this._getNews();
        this._getNotifications();

        setTimeout(() => { window.dispatchEvent(new Event('resize')) } , 50);
    }

    componentDidUpdate() {
        const isAppRunning = window.rnIntegration && window.rnIntegration.isAppRunning();
        const pushConflict = notificationManager.notifications.getPushUserConflict();
        if(JSON.stringify(pushConflict) !== JSON.stringify(this.state.pushConflict) && isAppRunning) {
            this.setState({ pushConflict });
        }
    }

    componentWillUnmount() {
        genesysUtils.general.stopResizeMonitor(null, this._handleContentScroll);
        if(genesysUtils.deviceCheck.isDesktop()) {
            genesysUtils.general.stopResizeMonitor(this.contentResizeMonitor, this._updateContentArea);
        }
    }

    render() {
        const { authContext } = this.props;
        const { navigationBarHidden, menu, pushConflict } = this.state;
        const { allowDeviceMultiRecipients, patientsSameDevice } = pushConflict || {};
        const user = authContext.properties.user;
        const isMenuExpanded = menu && menu.active;
        const hideNavBar = isMenuExpanded && navigationBarHidden;
        const sectionOrder = this._buildSectionOrder();
        const showNavigationBar = genesysUtils.deviceCheck.isDesktop ? this.state.navigationBarOnDesktop : this.state.navigationBarOnMobile;
        const patientList = genesysUtils.array.arrayEllipsis(genesysUtils.typeCheck.isArray(patientsSameDevice) ? patientsSameDevice.filter(i => i) : [], 3);

        return(
            <>
                { pushConflict &&
                <AppConfirmationDialog
                    title="Atenção"
                    message={
                        <AppCustomMessage messageId="user-home_push-patient-conflict-mensagem" 
                            params={[
                                patientList.length > 1 ? "s" : "",
                                patientList.length ? ":" : ".",
                                <span className="mc-information">{patientList.map(pascalCase).join(", ")}</span>,
                                <AppCustomMessage messageId={allowDeviceMultiRecipients ? "user-home_push-patient-conflict-confirmar-adicionar" : "user-home_push-patient-conflict-confirmar-substituir"} />
                            ]}
                        />
                    }
                    hideCancelButton={false}
                    onConfirm={() => this._handlePushChangeUser()}
                    onCancel={() => this._handlePushCloseAlert()}
                />
                }

                { showNavigationBar &&
                <NavigationBar className={hideNavBar ? "hide-nav-bar" : null} />
                }

                <div className={`user-home-wrapper${!showNavigationBar ? " no-nav-bar" : ""}${hideNavBar ? " hide-nav-bar" : ""}`} onScroll={this._handleContentScroll}>
                    <div className="content-wrapper">
                        <div className="content-box">

                            <div className="identificacao-usuario">OLÁ, {upperCase(user.nome)}</div>

                            { sectionOrder.map((elem, indx) => {
                                if(!elem.enabled) {
                                    return null;
                                }
                                const commonParams = {
                                    position: indx,
                                    onAccordionClick: this._handleAccordionClick
                                }

                                switch(elem.section) {
                                    case SECTIONS.MENU: 
                                        return <HomeMenu key={`section_${indx}`} menu={this.state.menu} {...commonParams} />
                                    case SECTIONS.NOTIFICACAO: 
                                        return <Notificacoes
                                                    key={`section_${indx}`}
                                                    notifications={this.state.notifications} 
                                                    onOpenModule={this.props.appControllerContext.methods.doAddCardModule}
                                                    {...commonParams} 
                                                />
                                    case SECTIONS.NOVIDADES: 
                                        return <Novidades key={`section_${indx}`} news={this.state.news} {...commonParams} />;
                                    default: return null;
                                }
                            }) }

                            <div className="padding-rodape"></div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default UserHome;
