/*
** @name: Meu Clínicas - meusDados
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2021
** @description: Módulo para resposta do termo de consentimento (LGPD)
*/

import React, { Component } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import Moment from 'react-moment';
import moment from "moment";
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import specialAccessManager, { PERMISSIONS } from '../../core/specialAccessManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { isAppServiceEnabled } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { hideService } from '../../components/general/appNavigationControls/appNavigationControls.js';
import { AppCustomText } from '@hcpa-react-components/app-customization';

import lgpdIntegrationClient from '../../apiClients/login/lgpdIntegrationClient.js';


// Import module styles
import './termoConsentimento.scss'; 


const dadosConsentimentoUsuario = (user) => {
    const lgpdData = specialAccessManager.permissions.getDataFromUser(user, PERMISSIONS.LDPD);
    return user && lgpdData && 
            (user.pacCodigo===lgpdData.pacCodigo) && 
            genesysUtils.typeCheck.isInteger(lgpdData.dtResposta) && (lgpdData.concorda!==null) ? lgpdData : null;
}

const dadosTermoVigente = (user) => {
    const lgpdData = specialAccessManager.permissions.getDataFromUser(user, PERMISSIONS.LDPD);
    return lgpdData ? lgpdData.termoVigente : null;
}

const isRespostaConsentimentoVigente = (user) => {
    // Verifica se usuario respondeu o termo vigente 
    // true = respondeu o termo vigente
    // false = não respondeu o termo vigente
    // null = não há dados sobre termo de consentimento
    const lgpdData = dadosConsentimentoUsuario(user);
    if(!genesysUtils.typeCheck.isObject(lgpdData)) {
        const termoVigente = dadosTermoVigente(user);
        return genesysUtils.typeCheck.isObject(termoVigente) ? false : null;
    }
    const { dtResposta } = lgpdData;
    const { inicioVigencia } = lgpdData.termoVigente;
    const respondeu = (user.pacCodigo===lgpdData.pacCodigo) && genesysUtils.typeCheck.isInteger(lgpdData.dtResposta) && (lgpdData.concorda!==null);
    return respondeu && inicioVigencia && (dtResposta >= inicioVigencia) ? true : false;
}

const TermoConsentimento = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <TermoConsentimentoImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class TermoConsentimentoImplem extends Component {
    constructor(props) {
        super(props);

        const user = props.authContext.properties.user;
        const modeRequired = isRespostaConsentimentoVigente(user) === false;
        const modeEdit = (!modeRequired && !hideService(user, props.appContextConfig, APP_SERVICE_LIST.TERMO_CONSENTIMENTO));
        const consetimentoAtual = dadosConsentimentoUsuario(user);
        const atualizacaoTermo = this._isAtualizacaoTermo(user);
        this.state = {
            modeRequired : modeRequired,
            readyToStart: isAppServiceEnabled(props.appContextConfig, APP_SERVICE_LIST.TERMO_CONSENTIMENTO) && (modeRequired || modeEdit),
            concorda: ((atualizacaoTermo || !modeRequired) && consetimentoAtual ? consetimentoAtual.concorda : ""),
            downloadError: null,
            saveError: null,
        };
        
        this.afterErrorMsgRef = React.createRef();
    }

    _buildFilename = (dadosTermo) => {
        const { inicioVigencia } = dadosTermo;
        const data = genesysUtils.typeCheck.isInteger(inicioVigencia) ? moment(new Date(inicioVigencia)).format("DD-MM-YYYY") : "";
        return `termo-consentimento_${data}`;
    } 

    _clearErrors = () => {
        this.setState({ 
            downloadError: null,
            saveError: null
        });
    }

    _isAtualizacaoTermo = (user) => {
        const lgpdData = dadosConsentimentoUsuario(user);
        const respondeu = lgpdData && (user.pacCodigo===lgpdData.pacCodigo) && genesysUtils.typeCheck.isInteger(lgpdData.dtResposta) && (lgpdData.concorda!==null);

        return respondeu && !isRespostaConsentimentoVigente(user);
    }

    _isReadyToSave = (selecao, atualizacao) => {
        const lgpdData = dadosConsentimentoUsuario(this.props.authContext.properties.user);
        const consentimentoAtual = lgpdData ? lgpdData.concorda : null; 
        return selecao && genesysUtils.array.inArray(selecao.toUpperCase(), ['S', 'N']) && 
                (atualizacao || !consentimentoAtual || consentimentoAtual !== selecao);
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handeChangeAnswer = (e, value) => {
        this._clearErrors();
        this.setState({
            concorda: value
        });
    }

    _handleVisualizarTermo = () => {
        this._setLoading(true);
        this._clearErrors();

        lgpdIntegrationClient.obterTermo(
            null,
            false,
            res => {
                this._setLoading(false);

                if(!res.data || !res.data.termoBase64) {
                    this.setState({
                        downloadError: {
                            header: "Ops!",
                            message: "Retorno sem dados para o termo."
                        }
                    });
                    return;
                }

                const base64 = res.data.termoBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const fileName = this._buildFilename(res.data) + ".pdf";
                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }
            },
            err => {
                this._setLoading(false);
                this.setState({
                    downloadError: {
                        header: "Ops!",
                        message: "Erro obtendo dados do termo."
                    }
                });
            }
        );
    }

    _handleSaveAnswer = () => {
        this._setLoading(true);
        this._clearErrors();

        const { authContext } = this.props;
        const pacCodigo = authContext.properties.user.pacCodigo;
        lgpdIntegrationClient.gravarConsentimento(
            pacCodigo, 
            this.state.concorda,
            res => {
                this._setLoading(false);
                specialAccessManager.permissions.setDadosConsentimento(authContext, res.data);
                this._handleClose();
            },
            err => {
                this._setLoading(false);
                this.setState({
                    saveError: {
                        header: "Ops!",
                        message: "Erro gravando informação."
                    }
                });
            }
        );
    }
    
    _scrollToMessageDiv = () => {
        if(this.afterErrorMsgRef.current){
            this.afterErrorMsgRef.current.scrollIntoView({block: "end", behavior: "smooth"});
        }
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidMount() {
        if(!this.state.readyToStart) {
            console.error("Dados inssuficientes para responder termo de consentimento.");
            this._handleClose();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.saveError) {
            this._scrollToMessageDiv()
        }
    }

    render() {
        const user = this.props.authContext.properties.user;
        const { modeRequired, downloadError, saveError, concorda } = this.state;
        const consetimentoAtual = dadosConsentimentoUsuario(user);
        const atualizacaoTermo = this._isAtualizacaoTermo(user);
        const dtResposta = (atualizacaoTermo || !modeRequired) && consetimentoAtual ? consetimentoAtual.dtResposta : null;

        return(
            <AppCardModuleBasicWrapper moduleName="termo-consentimento">

                <div className="information-section">
                    
                    <AppCustomText
                        elemType="div"
                        className="info-header"
                        propertyPath="applicationConfig.servicesSettings.termoConsentimento.messages" 
                        propertyName="cabeacalho" />

                    { atualizacaoTermo &&
                    <div className="info-update">O nosso termo foi atualizado</div>
                    }

                    <AppCustomText 
                        elemType="div"
                        className="main-info"
                        propertyPath="applicationConfig.servicesSettings.termoConsentimento.messages" 
                        propertyName="mensagem-principal" />

                    <div className="visualizar-termo">
                        <div>
                            <button
                                id="button-visualizar-termo"
                                type="button"
                                className="app-compact-button"
                                onClick={() => this._handleVisualizarTermo()}>
                                Ver Termo
                            </button>
                        </div>
                    </div>

                    { downloadError &&
                    <AppMessageBox 
                        id="msg-download-error" 
                        className="error" 
                        messageData={downloadError} />
                    }
                </div>

                <div className="answer-section">

                    <div className="answer-box">
                        { dtResposta &&
                        <div className="data-hora-resposta">
                            Resposta em <Moment format="DD/MM/YYYY HH:mm">{dtResposta}</Moment>
                        </div>
                        }

                        <div className="message">Declaro que li o termo e:</div>

                        <Form name="formResposta" className="form-resposta">
                            <div className="radio-field radio-sim">
                                <Radio
                                    id="radioSimId"
                                    name="rbResposta"
                                    disabled={false}
                                    label={"Concordo"}
                                    checked={concorda && concorda.toUpperCase()==="S" ? true : false}
                                    value={"S"}
                                    toggle={false}
                                    slider={false}
                                    onDoubleClick={null}
                                    onContextMenu={null}
                                    onChange={(e, elem) => this._handeChangeAnswer(e, elem.value)} />
                            </div>
                            <div className="radio-field radio-nao">
                                <Radio
                                    id="radioNao-Id"
                                    name="rbResposta"
                                    disabled={false}
                                    label={"Não Concordo"}
                                    checked={concorda && concorda.toUpperCase()==="N" ? true : false}
                                    value={"N"}
                                    toggle={false}
                                    slider={false}
                                    onDoubleClick={null}
                                    onContextMenu={null}
                                    onChange={(e, elem) => this._handeChangeAnswer(e, elem.value)} />
                            </div>
                        </Form>
                    </div>

                    <div className="main-action">
                        <div>
                            <button 
                                id="button-ok"
                                type="button"
                                disabled={!this._isReadyToSave(concorda, atualizacaoTermo)}
                                className="app-compact-button"
                                onClick={() => this._handleSaveAnswer()}>SALVAR</button>
                        </div>
                    </div>

                </div>

                { (modeRequired && (downloadError || saveError)) &&
                <div className="main-action">
                    <div>
                        <button 
                            id="button-responder-depois"
                            type="button"
                            disabled={false}
                            className="app-compact-button"
                            onClick={() => this._handleClose()}>RESPONDER DEPOIS</button>
                    </div>
                </div>
                }

                { saveError &&
                <AppMessageBox 
                    id="msg-download-error" 
                    className="error" 
                    messageData={saveError} />
                }

                <div className="padding-rodape" ref={this.afterErrorMsgRef}></div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default TermoConsentimento;

export {
    isRespostaConsentimentoVigente
}