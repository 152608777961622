/*
** @name: Meu Clínicas - videoChamada
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Componente para renderizar uma video camada
*/

import React, { useState, useEffect } from 'react';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppServiceSettingsByName } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import JitsiMeetingRoom, { HANGUP_MODE } from '../../components/general/jitsiMeetingRoom/';

import Feedback from './feedback.js';


const VideoChamada = (props) => {
    const { jitsiApiLoaded, configuration, configurationErrorMessage, closedConference, startedAt, 
            onReconnect, onConferenteActiveChange, onBeforeHangup, onSair, onAnexar } = props;
    const [ connectionError, setConnectionError ] = useState(false);
    const [ feedbackReceived, setFeedbackReceived ] = useState(false);
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    const serviceConfig = getAppServiceSettingsByName(appContextConfig, APP_SERVICE_LIST.TELEATENDIMENTO) || {};
    const meetingDefaultBackgroundColor = serviceConfig.meetingDefaultBackgroundColor || "transparent";
    const maxTimeToJoin = serviceConfig.maxTimeToJoinConferenceMs;
    const isConfigReady = configuration && configuration.domain && configuration.room && configuration.jwtToken ? true : false;
    const hangupMode = serviceConfig.disableHangup ? HANGUP_MODE.DISABLED : (serviceConfig.useCustomHangup ? HANGUP_MODE.CUSTOM : HANGUP_MODE.DEFAULT);

    const _getErrorMessage = () => {
        if(configurationErrorMessage) {
            return configurationErrorMessage;
        }
        if(!isConfigReady) {
            return <AppCustomMessage messageId="teleatendimento_conference-configuration-error" />;
        }
        return !connectionError ? null : <AppCustomMessage messageId="teleatendimento_connection-error" />;
    }

    const _handleHangup = () => {
        if(onBeforeHangup) {
            onBeforeHangup();
        }
    }

    const _handleConferenceJoin = (jitsi, userData) => {
        if(onConferenteActiveChange) {
            onConferenteActiveChange(true);
        }
    }

    const _handleConnectionError = () => {
        setConnectionError(true);
        if(onConferenteActiveChange) {
            onConferenteActiveChange(false);
        }
    }

    const _showLoadingConference = (visible) => {
        utils.setLoadingVisibility(appControllerContext, visible);
    }

    useEffect(() => {
        setConnectionError(false);
    }, []);

    const errorMessage = _getErrorMessage();

    return(
        <div className='video-chamada-wrapper'>

            { errorMessage &&
            <>
                <AppMessageBox 
                    id="msg-room-error" 
                    className="error" 
                    messageData={{ message: errorMessage }}
                />

                <div className='main-action'>
                    <button className="app-form-button" onClick={onSair}>Ok</button>
                </div>
            </>
            }

            { !jitsiApiLoaded &&
            <div className='error-missing-jitsi-api'>
                <AppCustomMessage messageId="teleatendimento_conference-api-not-loaded-error" />
            </div>
            }

            { (!errorMessage && closedConference) &&
            <div className='closed-connection-wrapper'>
                { !feedbackReceived &&
                <>
                    <AppCustomMessage messageId="teleatendimento_conference-closed" />
                    <div className='main-action'>
                        <button className="app-form-button" onClick={() => onReconnect()}>
                            <AppCustomMessage messageId="teleatendimento_video-chamada-retornar" />
                        </button>
                    </div>
                </>
                }

                <Feedback
                    startedAt={startedAt}
                    numeroConsulta={configuration.numeroConsulta}
                    authContext={authContext}
                    appControllerContext={appControllerContext}
                    appContextConfig={appContextConfig}
                    onFeedbackReceived={() => setFeedbackReceived(true)}
                    onCloseModule={onSair}
                />
            </div>
            }

            { (!errorMessage && !closedConference && isConfigReady && jitsiApiLoaded) &&
            <JitsiMeetingRoom
                domain={configuration.domain}
                roomId={configuration.room}
                roomName={configuration.roomName}
                jwtToken={configuration.jwtToken}
                meetingDefaultBackgroundColor={meetingDefaultBackgroundColor}
                hiddenWhileLoading={false}
                disableLog={true}
                maxTimeToJoin={maxTimeToJoin}
                hangupMode={hangupMode}
                autoHideCustomToolbar={serviceConfig.autoHideCustomToolbar}
                onMissingRequirements={_handleConnectionError}
                onConnectionTimeout={_handleConnectionError}
                onConferenceJoined={_handleConferenceJoin}
                onConferenceLeft={() => {}} // not in use due to onHangup event
                onHangup={_handleHangup}
                onUpload={serviceConfig.uploadOverVideo ? onAnexar : null}
                onStartLoading={() => _showLoadingConference(true)}
                onFinishLoading={() => _showLoadingConference(false)}
            />
            }

        </div>
    );
}

export default VideoChamada;