/*
** @name: Meu Clínicas - smsValidationModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2023
** @description: Reafatorado componente e ajuste do tratamento de erro no envio do SMS
*/

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import $ from 'jquery';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../../core/utils.js';
import { useAppControllerContext } from '../../../core/appControllerContext.js';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { getAppGeneralSettingsPropertyByName } from '../../../core/appSpecificConfigHandler.js';

import AppMessageBox from '../appMessageBox/appMessageBox.js';

import usuarioClient from '../../../apiClients/login/usuarioClient.js';


const SmsValidation = (props) => {
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <SmsValidationImplem
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class SmsValidationImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newTokenMessage: null,
            validateError: null,
            enableCancel: false,
            enableResendToken: false,
            resetFocusInput: true,
            smsActivationToken: genesysUtils.typeCheck.isObject(this.props.smsActivationToken) ? this.props.smsActivationToken : {}
        }
    }

    _clearMessages = (resetFocus) => {
        this.setState({
            newTokenMessage: null,
            validateError: null,
            enableCancel: false,
            enableResendToken: false,
            resetFocusInput: resetFocus ? true : this.state.resetFocusInput
        });
    }

    _focusInputField = () => {
        $("#input-sms-token").focus();
        this.setState({ resetFocusInput: false });
    }

    _getFieldClasses = (fieldName) => {
        const field = this.state.form.fields[fieldName];
        let classes = "";
        classes += field.value ? 'has-content' : 'empty';
        classes += field.errorMessage ? ' invalid' : ' valid';

        return classes;
    }

    _handleTokenChange = (value) => {
        const smsTokenValue = (!value ? "" : value.replace(/[^0-9]/g, '')).substr(0, 5);
        this._updateActivationToken(undefined, undefined, smsTokenValue);
        this._clearMessages();
    }

    _handleValidationSkip = () => {
        this._updateActivationToken(undefined, null, null, false, true);
        this.props.onComplete();
    }

    _processarEnvioTokenSms = (e) => {
        if(e) {
            e.stopPropagation();
            e.preventDefault();
        }

        const { cpf, numCelular } = this.props;
        if(!cpf || !numCelular) {
            return;
        }

        this._setLoading(true);
        this._clearMessages(true);

        usuarioClient.enviarTokenSmsByNumber(
            cpf, numCelular,
            res => {
                this._setLoading(false);
                if(res.data.smsSent) {
                    this._updateActivationToken(true);
                } else {
                    // Apenas proteção contra uma situação inesperada (sucesso porém sms não enviado)
                    this._handleValidationSkip();
                }
            },
            err => {
                this._setLoading(false);
                if(err.response && err.response.data) {
                    const { status } = err.response;
                    const enableResendToken = (status!==400 && status!==409 && status!==429) ? true : false;
                    const enableCancel = !enableResendToken && status!==429;
                    const alreadySent = status===429 ? true: undefined;

                    this._updateActivationToken(alreadySent, undefined, null);
                    this.setState({
                        enableCancel,
                        enableResendToken,
                        validateError: { 
                            header: "Atenção",
                            message: err.response.data.errorMessage
                        }
                    }); 
                } else {
                    this.setState({
                        enableResendToken: true,
                        validateError: { 
                            header: "Ops!",
                            message: "Ocorreu um erro realizando o envio do SMS"
                        }
                    });
                }
            }
        );
    }

    _processarValidacaoTokenSms = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this._setLoading(true);
        this._clearMessages(true);

        const { tokenId, typedToken } = this.state.smsActivationToken;
        const { cpf, numCelular } = this.props;
        usuarioClient.validarTokenSms(
            typedToken, tokenId, cpf, numCelular,
            res => {
                this._setLoading(false);

                if(res.data.valid) {
                    this._updateActivationToken(undefined, res.data.tokenId, undefined, true);
                    this.props.onComplete();
                }
            },
            err => {
                this._setLoading(false);

                if(err.response && err.response.data) {
                    this._updateActivationToken(undefined, undefined, null);
                    this.setState({
                        enableResendToken: true,
                        validateError: { 
                            header: "Atenção",
                            message: err.response.data.errorMessage
                        }
                    }); 
                } else {
                    this.setState({
                        validateError: { 
                            header: "Ops!",
                            message: "Ocorreu um erro processando a validação"
                        }
                    });
                }
            }
        );
    }

    _updateActivationToken = (sent, tokenId, typedToken, confirmed, skipped) => {
        const newActivationToken = this.state.smsActivationToken || {};
        if(sent!==undefined) newActivationToken.sent = true;
        if(tokenId!==undefined) newActivationToken.tokenId = tokenId;
        if(typedToken!==undefined) newActivationToken.typedToken = typedToken;
        if(confirmed!==undefined) newActivationToken.confirmed = confirmed;
        if(skipped!==undefined) newActivationToken.skipped = skipped;

        this.setState({ smsActivationToken: newActivationToken });
        this.props.onTokenChange(newActivationToken);
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _validateForm = () => {
        const { cpf, numCelular } = this.props;
        const { typedToken } = genesysUtils.typeCheck.isObject(this.state.smsActivationToken) ? this.state.smsActivationToken : {};
        return cpf && numCelular && typedToken && typedToken.length === 5;
    }

    componentDidMount() {
        const { smsActivationToken } = this.state;
        if(!smsActivationToken.sent) {
            this._processarEnvioTokenSms();
        }

        setTimeout(() => this._focusInputField(), 100);
    }

    componentDidUpdate() {
        if(this.state.resetFocusInput) {
            this._focusInputField();
        }
    }

    render() {
        const { cpf, numCelular } = this.props;
        if(!cpf || !numCelular) {
            return(
                <div className="info-principal">
                    CPF e/ou número do celular não informado(s).
                </div>
            )
        }

        const { typedToken } = this.state.smsActivationToken || {};
        const { enableCancel, enableResendToken } = this.state;
        const enableValidation = !enableCancel;
        const appName = getAppGeneralSettingsPropertyByName(this.props.appContextConfig, "app-name");
        return(
            <div className="sms-validation-wrapper">

                <div className="info-principal">
                    { enableValidation ?
                    <>
                        O {appName} enviou um SMS para verificar o número de celular informado.
                        Digite o código do SMS enviado para o celular { numCelular }.
                    </>
                    :
                    <>
                        Validação do numero de celular
                    </>
                    }
                </div>

                <Form className="sms-validation-form">
                    <div className="token-input-wrapper">
                        <div className="float-label-field sms-token">
                            <Input
                                fluid
                                id="input-sms-token"
                                name="sms-localizador"
                                disabled={!enableValidation}
                                className=""
                                autoComplete="off"
                                placeholder='_____' 
                                value={typedToken ? typedToken : ""}  
                                onChange={(e) => this._handleTokenChange(e.target.value)}
                            />                                                     
                        </div>
                    </div>

                    { this.state.validateError &&
                    <AppMessageBox 
                        id="msg-sms-error" 
                        className="error" 
                        messageData={this.state.validateError} />
                    }

                    { this.state.newTokenMessage &&
                    <AppMessageBox 
                        id="msg-new-token-success" 
                        className="information"
                        messageData={this.state.newTokenMessage} />
                    }

                    <div className="main-action">
                        { enableResendToken &&
                        <button
                            id="button-reenviar-codigo"
                            type="button"
                            className="app-form-button"
                            onClick={(e) => this._processarEnvioTokenSms(e)}>
                            ENVIAR OUTRO CÓDIGO
                        </button>
                        }

                        { enableCancel &&
                        <button
                            id="button-voltar"
                            type="button"
                            className="app-form-button"
                            onClick={() => this.props.onCancel()}>
                            VOLTAR
                        </button>
                        }

                        { (!enableCancel && this.props.allowSkip) &&
                        <button
                            id="button-validar"
                            type="button"
                            className="app-form-button"
                            onClick={(e) => this._handleValidationSkip(e)}>
                            VALIDAR DEPOIS
                        </button>
                        }

                        { !enableCancel &&
                        <button
                            id="button-validar"
                            type="default"
                            className="app-form-button"
                            disabled={!this._validateForm()}
                            onClick={(e) => this._processarValidacaoTokenSms(e)}>
                            OK
                        </button>
                        }

                    </div>  
                </Form>

            </div>
        );
    }
}

export default SmsValidation;