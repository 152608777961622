/*
** @name: Meu Clínicas - tools
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo de rotinas gerais/auxiliares para teleatendimento
*/

import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { getAppServiceSettingsByName } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import wikiClient from '../../apiClients/wiki/wikiClient.js';


const DEVICE_NOT_PRESENT_ERROR = "Para acessar esse módulo você precisa de câmera, microfone e alto-falante/fone instalados e ativos.";
const DEVICE_NOT_ALLOWED_ERROR = "Para acessar esse módulo você deve autorizar o acesso à câmera e ao microfone.";
const INVALID_CONTENT_PROTOCOL = "Para acessar esse módulo é necessário uma conexão segura (https)";

const addModuleTimeout = (timeoutList, name, func, timeout) => {
    clearModuleTimeout(timeoutList, name);
    timeoutList[name] = setTimeout(func, timeout);
}

const clearModuleTimeout = (timeoutList, id) => {
    Object.keys(timeoutList).forEach(k => {
        const timeoutId = timeoutList[k];
        if(!id || id===k) {
            if(timeoutId) {
                clearTimeout(timeoutId);
            } 
        }
    });
}

const calculateTeleAdjustedTimes = (appCfg, infoTele) => {
    const now = new Date().getTime();
    const adjustedTimes = {
        updateAt: now,
        checkinStartAt: now + calculateTimeToCheckin(appCfg, infoTele),
        beginAt: now + infoTele.msToBegin
    }
    return adjustedTimes;
}

const calculateTimeToCheckin = (appCfg, infoConsulta) => {
    const { msToBegin, msToCheckin } = infoConsulta;
    const serviceConfig = getAppServiceSettingsByName(appCfg, APP_SERVICE_LIST.TELEATENDIMENTO);
    const { allowCheckinAnyTime, maxTimeToCheckinMs } = serviceConfig;
    const timeToCheckin = allowCheckinAnyTime ? 0 : Math.max(msToCheckin, msToBegin-(maxTimeToCheckinMs||0));
    return timeToCheckin;
}

const faqItemLoad = (itemId) => {
    return new Promise((resolve, reject) => {
        // Buscar userFaq with specific questionId
        wikiClient.userFaq(
            itemId, false, null,
            res => {
                const result = res.data.listUserFaq;
                resolve(result && result.constructor===Array && result.length===1 ? result : null);
            },
            err => reject(err)
        );
    });
}

const getMediaDevicesPermissions = async (allowNonHttpsProtocol, onShowLoading, onHideLoading) => {
    return new Promise(async resolve => {
        // Check for secure 'https' protocol
        if(!genesysUtils.general.isHttpsProtocol() && !allowNonHttpsProtocol) {
            resolve({
                mediaDevicesReady: false,
                mediaDevicesErrorMessage: INVALID_CONTENT_PROTOCOL 
            });
            return;
        }

        // Check App specific permissions
        if(genesysUtils.deviceCheck.isMobile()) {
            const rnIntegration = window.rnIntegration;
            const mobileDevicePermissions = (rnIntegration && rnIntegration.isAppRunning()) ? rnIntegration.getDevicePermissions() : null;
            if(!mobileDevicePermissions || !mobileDevicePermissions.camera || !mobileDevicePermissions.microphone) {
                resolve({
                    mediaDevicesReady: false,
                    mediaDevicesErrorMessage: DEVICE_NOT_ALLOWED_ERROR 
                });
                return;
            }
        }

        // Get media devices information and permissions (browser/webview)
        const present = await genesysUtils.general.asyncMediaDevicePresent()
            .then(info => (info && info.camera && info.microphone && (info.speeker || genesysUtils.deviceCheck.isIOS())));
        if(!present) {
            resolve({
                mediaDevicesReady: false,
                mediaDevicesErrorMessage: DEVICE_NOT_PRESENT_ERROR
            });
            return false;
        }

        onShowLoading();
        const allowed = await genesysUtils.general.asyncMediaStream({video: true, audio: true})
            .then(stream => {
                onHideLoading();
                if(!stream) {
                    return false;
                }
                try {
                    stream.getTracks().forEach(track => track.stop());
                } catch(err) {
                    console.error(`Error stopping media devices streaming track: ${err.message}`)
                }
                return true;
            });
        resolve({ 
            mediaDevicesReady: allowed,
            mediaDevicesErrorMessage: allowed ? null : DEVICE_NOT_ALLOWED_ERROR 
        });
    });
}

const newsLoad = (location) => {
    return new Promise((resolve, reject) => {
        // Buscar news by exhibit location
        wikiClient.newsByLocation(
            location,
            res => {
                const result = res.data.newsList;
                resolve(result && result.constructor===Array && result.length>0 ? result : null);
            },
            err => reject(err)
        );
    });
}

export {
    addModuleTimeout,
    clearModuleTimeout,
    calculateTeleAdjustedTimes,
    calculateTimeToCheckin,
    faqItemLoad,
    getMediaDevicesPermissions,
    newsLoad
}