/*
** @name: Meu Clínicas - pesquisaExperiencia
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2020
** @description: Módulo para criar formulários de pesquisa de experiência do paciente
**
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizando tratamento do retorno de erro para novo sistema de validacao
*/

import React, { Component } from 'react';
import FormBuilder from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import { emptyToNull, pascalCase } from '@hcpa-react-components/string-utils';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { CheckboxField, DropdownField, RadioGroupField, InputField } from '../../components/fields/formsBuilderCustoms/';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';

import { ActionButtonFormPesq, MensagemErroInicializacaoFormPesq, MensagemSucessoPesq } from './mensagens.js'
import { FORM_CONFIG, STEPS, buildConfigPesquisa, configureForms, updateConfigDataNascimento } from './configuration.js';

// Import module styles
import './pesquisaExperiencia.scss'; 


const ERROR_CLOSE_TIMEOUT = 7000;

let autoCloseTimeoutId = null;

const PesquisaExperiencia = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    return(
        <PesquisaExperienciaImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class PesquisaExperienciaImplem extends Component {
    constructor(props) {
        super(props);

        const { disableSelecao } = sessionStorageManager.navigation.getCurrentCardModuleParameters() || {};
        this.state = {
            currentStep: STEPS.INITIALIZE,
            fields: null,
            resultadoRegistro: null,
            erroRegistro: null,
            renderForm: true,
            exibirMensagemSelecaoPesquisa: disableSelecao ? false : true,
            initializationError: false,
            formConfigurations: null
        }
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            const { currentStep } = this.state;
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(currentStep!==STEPS.INITIALIZE && currentStep!==STEPS.SELECAO) {
                rnIntegration.backAction.push(() => { this._handleVoltar() });
            }
        }
    }

    _buildRequest = () => {
        const user = this.props.authContext.properties.user;
        const fields = this.state.fields;
        if(!user || !fields || fields.constructor!==Object) {
            return;
        }

        let fieldAnswerKey = "";
        switch(fields["selecaoPesquisa"].value) { // mapeamento para campo do backend
            case "AMB": fieldAnswerKey = "ambulatorio"; break;
            case "EXM": fieldAnswerKey = "exames"; break;
            case "INT": fieldAnswerKey = "internacao"; break;
            case "TELE": fieldAnswerKey = "teleatendimento"; break;
            default: return;
        }

        const request = {};
        request["selecaoPesquisa"] = fields["selecaoPesquisa"].value;
        request["pesquisaAnonima"] = fields["pesquisaAnonima"].value ? true : false;
        request["pacCodigo"] = user.pacCodigo;
        request["prontuarioPaciente"] = user.prontuario;
        request["nomePaciente"] = pascalCase(user.nome);
        request["emailPaciente"] = user.email;
        request["telefonePaciente"] = user.celular;

        request["indicacaoHospital"] = fields["indicacaoHospital"].value;
        request["elogio"] = emptyToNull(fields["elogio"].value);
        request["sugestao"] = emptyToNull(fields["sugestao"].value);
        request["critica"] = emptyToNull(fields["critica"].value);

        const ignoreFiels = ["selecaoPesquisa", "pesquisaAnonima", "indicacaoHospital", "elogio", "sugestao", "critica"];
        const dateFields = ["dataNascimento"];
        request[fieldAnswerKey] = {};
        Object.keys(fields).forEach((key) => {
            if(!ignoreFiels.includes(key)) {
                let value = fields[key].value;
                if(value && dateFields.includes(key)) {
                    const date = utils.stringToDateBR(value);
                    value = date ? date.getTime() : null;
                }
                request[fieldAnswerKey][key] = value;
            }
        });

        return request;
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(genesysUtils.typeCheck.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _handleAction = () => {
        const { formConfigurations } = this.state;
        const user = this.props.authContext.properties.user;
        const anonymous = this._isAnonymous();

        let newState = this.state.currentStep;
        switch(this.state.currentStep) {
            case STEPS.SELECAO:
                const selecao = this.state.fields.selecaoPesquisa;
                if(selecao) {
                    switch(selecao.value) {
                        case "AMB": 
                            newState = STEPS.PESQUISA_AMBULATORIAL;
                            updateConfigDataNascimento(formConfigurations[FORM_CONFIG.PESQUISA_AMBULATORIAL], user, anonymous, this._setFieldState);
                            break;
                        case "EXM": 
                            newState = STEPS.PESQUISA_EXAME; 
                            updateConfigDataNascimento(formConfigurations[FORM_CONFIG.PESQUISA_EXAME], user, anonymous, this._setFieldState);
                            break;
                        case "INT":
                            newState = STEPS.PESQUISA_INTERNACAO;
                            updateConfigDataNascimento(formConfigurations[FORM_CONFIG.PESQUISA_INTERNACAO], user, anonymous, this._setFieldState);
                            break;
                        case "TELE":
                            newState = STEPS.PESQUISA_TELEATENDIMENTO;
                            updateConfigDataNascimento(formConfigurations[FORM_CONFIG.PESQUISA_TELEATENDIMENTO], user, anonymous, this._setFieldState);
                            break;
                        default:
                            this._setFieldState("selecaoPesquisa", { errorMessage: "Você deve escolher uma pesquisa" });
                            break;
                    }
                }
                break;
            case STEPS.PESQUISA_AMBULATORIAL:
            case STEPS.PESQUISA_EXAME:
            case STEPS.PESQUISA_INTERNACAO:
            case STEPS.PESQUISA_TELEATENDIMENTO:
                this._registrarPesquisa();
                break;
            default:
                return null;
        }

        if(newState !== this.state.currentStep) {
            this.setState({ 
                currentStep: newState
            });
        }
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleFormUpdate = (fields) => {
        this.setState({ fields: fields });
    }

    _handleVoltar = () => {
        this.setState({
            currentStep: STEPS.SELECAO,
            resultadoRegistro: null,
            erroRegistro: null,
            fields: null,
            renderForm: false
        });
    }

    _initilize = () => {
        this._setLoading(true);
        const reqConfig = Object.values(FORM_CONFIG).sort();
        formulariosClient.obterConfiguracoesFormularios(
            reqConfig,
            (res => {              
                this._setLoading(false);
                if(genesysUtils.typeCheck.isObject(res.data)) {
                    const keys = Object.keys(res.data).sort();
                    if(utils.arrayCompare(keys, reqConfig)) {
                        const { selecaoPesquisa } = sessionStorageManager.navigation.getCurrentCardModuleParameters() || {};
                        configureForms(res.data, selecaoPesquisa, this.state.exibirMensagemSelecaoPesquisa)
                        this.setState({
                            currentStep: STEPS.SELECAO,
                            formConfigurations: res.data
                        });
                        return;
                    }
                }
                throw new Error("[Configuration-Pesq] Invalid configuration response.");
        }),
            (err => { 
                this._setLoading(false);
                this.setState({ initializationError: true });
                console.error("Error:", err);
            })
        );
    }

    _isAnonymous = () => {
        const { pesquisaAnonima } = this.state.fields || {};
        return (pesquisaAnonima && pesquisaAnonima.value) ? true : false;
    }

    _processaErrosFormulario = (erros) => {
        if(erros && erros.constructor === Object) {
            const updateFields = this.state.fields;
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    let message = erros[key];
                    if(!updateFields[key]) {
                        updateFields[key] = {
                            value: null,
                            errorMessage: null
                        }
                    }
                    updateFields[key].errorMessage = message;
                }
            });
            this.setState({
                fields: updateFields
            });
        }
    }

    _registrarPesquisa = () => {
        this._setLoading(true);
        this._setErroRegistro(null);
        this._clearFieldsError();

        const request = this._buildRequest();

        formulariosClient.pesquisaExperiencia(
            request,
            (res => {              
                const result = res.data;
                this._setLoading(false);
                this.setState({ resultadoRegistro: result });

                if(!result.valid) {
                    const validatorResponse = result.validatorResponse || {};
                    if(validatorResponse.errors && validatorResponse.errors.erroGeral) {
                        this._setErroRegistro(validatorResponse.errors.erroGeral);
                    } else {
                        this._setErroRegistro("Por favor, verifique o correto preenchimento do formulário.");
                    }
                    this._processaErrosFormulario(validatorResponse.errors);
                }
            }),
            (err => { 
                this._setLoading(false);
                this._setErroRegistro("Ops!, ocorreu um erro registrando sua pesquisa.");
            })
        );
    }

    _setErroRegistro = (msg) => {
        this.setState({ erroRegistro: msg });
    }

    _setFieldState = (fieldName, { value, errorMessage, disabled }) => {
        const updateFields = JSON.parse(JSON.stringify(this.state.fields));
        if(!updateFields[fieldName]) {
            updateFields[fieldName] = { value: null, errorMessage: null, disabled: null };
        }
        if(value!==undefined) {
            updateFields[fieldName].value = value;
        }
        if(errorMessage!==undefined) {
            updateFields[fieldName].errorMessage = errorMessage;
        }
        if(disabled!==undefined) {
            updateFields[fieldName].disabled = disabled;
        }
        this.setState({ fields: updateFields });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidMount() {
        this._initilize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.currentStep!==prevState.currentStep) {
            this._appSetBackAction();
            utils.scrollAppCardModuleContentTo(0);
        }
        if(!this.state.renderForm) {
            this.setState({ renderForm: true });
        }
        if(this.state.initializationError && !prevState.initializationError) {
            autoCloseTimeoutId = setTimeout(() => this._handleClose(), ERROR_CLOSE_TIMEOUT);
        }
    }

    componentWillUnmount() {
        if(autoCloseTimeoutId) {
            clearTimeout(autoCloseTimeoutId);
        }
    }

    render() {
        const { currentStep, exibirMensagemSelecaoPesquisa, initializationError, resultadoRegistro, erroRegistro, formConfigurations, fields, renderForm } = this.state;
        const configPesquisa = buildConfigPesquisa(formConfigurations, currentStep, exibirMensagemSelecaoPesquisa);
        const exibeMensagemSucesso = resultadoRegistro && resultadoRegistro.valid;
        const exibeConteudoModulo = !initializationError && (currentStep !== STEPS.INITIALIZE) && !exibeMensagemSucesso;
        return(
            <AppCardModuleBasicWrapper moduleName="pesquisa-experiencia">

                { initializationError && <MensagemErroInicializacaoFormPesq onClick={this._handleClose}/> }

                { exibeMensagemSucesso && <MensagemSucessoPesq onClick={this._handleClose}/> }

                { exibeConteudoModulo && 
                <>
                    { !configPesquisa ?
                        <AppMessageBox
                            id="msg-error"
                            className="error"
                            messageData={{ "message": "Ops!, configuração inexistente." }} />
                        :
                        <>
                            <div className={`${configPesquisa.className}`}>
                                <div className="section-box">
                                    <div className="section-title">{configPesquisa.title}</div>
                                    <div className="section-content">
                                        { configPesquisa.instruction && 
                                        <div className="instruction">
                                            {configPesquisa.instruction}
                                        </div>
                                        }

                                        <div className="form-wrapper">
                                            { renderForm &&
                                            <Form name="formMain">
                                                <FormBuilder 
                                                    blockFieldUpdate={false}
                                                    disableClearErrorOnFieldChange={false}
                                                    config={configPesquisa.formConfig}
                                                    fields={fields}
                                                    page={0}
                                                    className="form-content" 
                                                    onChange={this._handleFormUpdate}
                                                    overrideFieldRender={{
                                                        'checkbox': CheckboxField,
                                                        'dropdown': DropdownField,
                                                        'radio-group': RadioGroupField,
                                                        'input': InputField
                                                    }}
                                                />
                                            </Form>
                                            }
                                        </div>

                                        { configPesquisa.footer && 
                                        <div className="footer">
                                            {configPesquisa.footer}
                                        </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            { erroRegistro &&
                            <AppMessageBox
                                id="msg-error"
                                className="error"
                                messageData={{ "message": erroRegistro }} />
                            }

                            <ActionButtonFormPesq id="peBtnEnviar" text={configPesquisa.buttonMessage} onClick={this._handleAction}>
                                { configPesquisa.enableVoltar &&
                                <button className="app-form-button btn-voltar" onClick={() => this._handleVoltar()}>Voltar</button>
                                }
                            </ActionButtonFormPesq>

                            <div className="padding-rodape"></div>
                        </>
                    }
                </> 
                }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default PesquisaExperiencia;