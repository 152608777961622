import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";
import moment from "moment";

import { apiUrl } from '@hcpa-react-components/api-url-builder';


const API_PORT = 8082;
const API_SERVICE = 'exames-service';
const API_VERSION = 'v2';

class ExamesClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    } 

    buscarListaExames(pacCodigo, dtInicial, dtFinal, successCallback, errorCallback) { 
        var queryParams = {};
     
        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/exames/lista/${pacCodigo}`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarResultadoPDF(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/resultado/${id}/pdf`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarResultadoPNG(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/resultado/${id}/png`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarAnexoExamePDF(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/anexo-exame/${id}/pdf`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarAnexoExamePNG(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/anexo-exame/${id}/png`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarSolicitacaoPDF(codSolicitacao, successCallback, errorCallback) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/solicitacao/pdf/${codSolicitacao}/1`),
            successCallback,
            errorCallback);
    }

    buscarSolicitacaoPNG(codSolicitacao, successCallback, errorCallback) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/solicitacao/png/${codSolicitacao}/1`),
            successCallback,
            errorCallback);
    }

    cancelarCompartilhamentosXeroViewer(pacCodigo, soeSeq, iseSeq, successCallback, errorCallback) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/estudos/cancelar-compartilhamentos/${pacCodigo}/${soeSeq}/${iseSeq}`),
            successCallback,
            errorCallback);
    }

    obterTokenCompartilhamentoXeroViewer(pacCodigo, soeSeq, iseSeq, successCallback, errorCallback) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/estudos/compartilhar/${pacCodigo}/${soeSeq}/${iseSeq}`),
            successCallback,
            errorCallback);
    }

    obterUrlXeroViewer(pacCodigo, soeSeq, iseSeq, successCallback, errorCallback) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/estudos/${pacCodigo}/${soeSeq}/${iseSeq}`),
            successCallback,
            errorCallback);
    }

    obterUrlXeroViewerByToken(token, successCallback, errorCallback) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/public/estudos/${token}`),
            successCallback,
            errorCallback);
    }
}

const examesClient = new ExamesClient();
export default examesClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};