/*
** @name: Meu Clínicas - exames
** @author: 
** @date:
** @description: Módulo para cadastro do usuario.
**
** @update: Julho 2020 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Implementando visualizador do exame como alternativa ao download do PDF
**
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Julho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Implementando parte de estudos de exames (visualização, compartilhamento)
**
** @update: Setembro 2024 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Refatorar componente em componentes menores
*/
/*
    Modulos extras:
        COMPARTILHAR_ESTUDO: opcional
*/

import React, { Component } from 'react';
import { Accordion } from 'semantic-ui-react';
import Moment from 'react-moment';
import { useAppConfigContext, AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getAppServiceSettingsByName, isAppServiceEnabled } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppDateFilter from '../../components/general/appDateFilter/appDateFilter.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';

import SectionAnexos from './sectionAnexos.js';
import SectionEstudos from './sectionEstudos.js';
import SectionResultados from './sectionResultados.js';
import SectionSolicitacao from './sectionSolicitacao.js';
import Visualizador from './visualizador.js';

import examesClient from '../../apiClients/exames/examesClient';


// Import module styles
import './exames.scss';


const ESCAPE_KEY = 27;
const DEFAULT_ERRO_SOLICITACAO = "Erro obtento informações da solicitação.";
const DEFAULT_ERRO_EXAME = "Erro obtento informações do exame.";
const DEFAULT_ERRO_ESTUDO = "Erro obtento informações do visualizador.";
const DEFAULT_ERRO_ANEXO = "Erro obtento informações do anexo.";
const DEFAULT_ERRO_COMPATILHAMENTO = "Erro criando compartilhamento de imagens.";
const DEFAULT_ERRO_REVOGA_COMPARTILHAMENTO = "Erro ao cancelar compartilhamentos."


const Exames = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <ExamesImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class ExamesImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFiltered: false,
            filterError: null,
            searchResult: [],
            searchErrorMessage: null,
            searchInfoMessage: null,
            mostraSolicitacao: false,
            listaSolicitacao: null,
            mostraResutado: false,
            listaResultado: null,
            listaAnexo: null,
            mostraAnexo: false,
            confirmDialog: null
        };
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(this._getDadosVisualizacao()) {
                rnIntegration.backAction.push(() => { this._handleCloseResultado() });
            }
        }
    }

    _estudoAbrir = (estudo, grupo) => {
        this._setLoading(true);
        examesClient.obterUrlXeroViewer(
            estudo.pacCodigo,
            estudo.soeSeq,
            estudo.iseSeq,
            (res => {
                this._setLoading(false);
                const urlXero = res.data ? res.data.urlXero : null;
                if(res.status !== 200 || !urlXero) {
                    this._updateEstudoViewerError(estudo, DEFAULT_ERRO_ESTUDO);
                    return;
                }

                this._updateEstudoViewerError(estudo, null);
                estudo.statusLeituraEstudo = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState({ });
                this._openXeroViewer(urlXero);
            }),
            (err => {
                this._setLoading(false); // 400 (Requisição inválida) ou 500 (erro na chamada à API)
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_ESTUDO);
            })
        );
    }

    _estudoAbrirConfirmacao = (estudo, grupo) => {
        const closeConfirmation = () => this.setState({ confirmDialog: null });
        this.setState({
            confirmDialog: {
                title: "Atenção",
                message: <div><p>Esta funcionalidade irá direcionar para o portal de imagens. Certifique-se de que seu navegador não está com o bloqueio de popups ligado.</p><p>Confirma?</p></div>,
                onConfirm: () => { closeConfirmation(); this._estudoAbrir(estudo, grupo) },
                onCancel: () => closeConfirmation()
            }
        });
    }

    _estudoCompartilhar = (estudo) => {
        const abrirModuloCompartilhamento = (token) => {
            const urlBase = process.env.REACT_APP_SERVICES_ESTUDOS_IMAGENS;
            const params = {
                urlCompartilhamento: urlBase.replace(/\/+$/, "") + `/${token}`,
                descricaoEstudo: estudo.descricao
            }
            this.props.appControllerContext.methods.doAddCardModule(APP_SERVICE_LIST.COMPARTILHAR_ESTUDO, params, false);
        }

        this._setLoading(true);
        examesClient.obterTokenCompartilhamentoXeroViewer(
            estudo.pacCodigo,
            estudo.soeSeq,
            estudo.iseSeq,
            (res => {
                this._setLoading(false);
                if(res.status !== 200 || !res.data) {
                    this._updateEstudoViewerError(estudo, DEFAULT_ERRO_COMPATILHAMENTO);
                    return;
                }

                this._updateEstudoViewerError(estudo, null);
                this._updateContadorCompartilhamento(estudo, 1);
                abrirModuloCompartilhamento(res.data);
            }),
            (err => {
                this._setLoading(false);
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_COMPATILHAMENTO);
            })
        );
    }

    _estudoRevogarCompartilhamento = (estudo) => {
        const closeConfirmation = () => this.setState({ confirmDialog: null });

        this._setLoading(true);
        examesClient.cancelarCompartilhamentosXeroViewer(
            estudo.pacCodigo,
            estudo.soeSeq,
            estudo.iseSeq,
            (res => {
                this._setLoading(false);
                if(res.status === 200) {
                    this._updateEstudoViewerError(estudo, null);
                    this._updateContadorCompartilhamento(estudo, null, 0);

                    this.setState({
                        confirmDialog: {
                            title: "Sucesso",
                            message: <div><p>Todos compartilhamentos anteriores foram cancelados com sucesso, impedindo assim o acesso a essas imagens por esses links.</p><p>Você poderá realizar novos compartilhamentos quando desejar.</p></div>,
                            onConfirm: () => closeConfirmation(),
                            onCancel: null
                        }
                    });

                    return;
                }
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_REVOGA_COMPARTILHAMENTO);
            }),
            (err => {
                this._setLoading(false);
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_REVOGA_COMPARTILHAMENTO);
            })
        );
    }

    _estudoRevogarCompartilhamentoConfirmacao = (estudo) => {
        const closeConfirmation = () => this.setState({ confirmDialog: null });
        const contador = estudo.contadorCompartilhamento;
        const plural = contador > 1 ? "s" : "";
        this.setState({
            confirmDialog: {
                title: "Atenção",
                message: <div><p>No momento esta imagem possui {contador} compartilhamento{plural} ativo{plural}. Ao confirmar {plural ? "todos serão" : "este será"} cancelado{plural}, impedindo o acesso de quem possuir este{plural} link{plural}.</p><p>Você poderá realizar novos compartilhamentos quando desejar.</p><p>Confirma?</p></div>,
                onConfirm: () => { closeConfirmation(); this._estudoRevogarCompartilhamento(estudo) },
                onCancel: () => closeConfirmation()
            }
        });
    }

    _existsStatusNovo = (lista, statusField) => {
        return(
            genesysUtils.typeCheck.isArray(lista) && statusField &&
            lista.some((item) => {
                if(this._isStatusNovo(item[statusField])) {
                    return true;
                }
                return false;
            })
        )
    }

    _existsAnexoSolicitacao = (resultados) => {
        return( resultados && resultados.map((resultado, idx) => {
            if (resultado.idArmazenamentoAnexo !== null) {
                return true;
            }
            return false;
        }));
        
    }

    _getDadosVisualizacao = () => {
        const { mostraResutado, listaResultado, mostraSolicitacao, listaSolicitacao, listaAnexo, mostraAnexo } = this.state;
        const exibeVisualizador = (mostraResutado && listaResultado) || (mostraSolicitacao && listaSolicitacao) || (listaAnexo && mostraAnexo) ;
        const listaImages = exibeVisualizador &&  (listaResultado ? listaResultado : (listaSolicitacao ? listaSolicitacao : listaAnexo));

        return exibeVisualizador ? listaImages : null;
    }

    _getListaExames = (params) => {
        const filtro = (params && params.filtro) || {};
        const filterError = params && params.filterError;

        this.setState({ filterError });
        if (filterError) {
            return;
        }

        this._setLoading(true);
        this.setState({
            searchErrorMessage: null,
            searchInfoMessage: null
        });

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarListaExames(
            pacCodigo,
            filtro.dtInicial,
            filtro.dtFinal,
            res => {
                this._setLoading(false);
                const isFiltered = (filtro.dtInicial || filtro.dtFinal) ? true : false;
                const listGrupoExibicao = res.data && res.data.gruposExibicao ? res.data.gruposExibicao : [];
                const searchInfoMessage = listGrupoExibicao.length > 0 ? null :
                    (!isFiltered && utils.isUserInSyncAwayTime() ? <AppCustomMessage messageId="_general_mensagem-possivel-sincronismo-pendente" /> : "Nenhum resultado encontrado");
                this.setState({
                    isFiltered: isFiltered,
                    searchResult: listGrupoExibicao,
                    searchInfoMessage: searchInfoMessage
                });
            },
            err => {
                this._setLoading(false);
                this.setState({
                    searchResult: [],
                    searchErrorMessage: "Ocorreu um erro ao processar sua requisição"
                });
            }
        )
    }

    _getResultadoPDF = (exame, grupo) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarResultadoPDF(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);
                if(!res.data || !res.data.pdfBase64) {
                    this._updateResultadoDownloadError(exame, "Retorno sem dados para consulta.");
                    return;
                }
                this._updateResultadoDownloadError(exame, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const dtSolicitacao = exame.dtSolicitacao ? new Date(exame.dtSolicitacao) : new Date();
                    const dataSolicitacao = dtSolicitacao.toISOString().split('T')[0].replace(/-/g, '');
                    const soeSeq = exame.soeSeq;
                    const grupo = exame.nomeGrupoExame;
                    const fileName = `${dataSolicitacao}_${soeSeq}_${grupo}.pdf`;

                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }

                exame.statusLeituraResultado = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;

                this.setState(this.state);
            },
            err => {
                this._setLoading(false);
                this._updateResultadoDownloadError(exame, DEFAULT_ERRO_EXAME);
            });
    }

    _getResultadoPNG = (exame, grupo) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarResultadoPNG(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);

                if(!res.data || !res.data.listPngBase64 ||
                    isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length<1) {

                    this._updateResultadoDownloadError(exame, "Retorno sem dados para consulta.");
                    this.setState({ mostraResutado: false, listaResultado: null });
                    return;
                }
                this._updateResultadoDownloadError(exame, null);

                exame.statusLeituraResultado = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState({ mostraResutado: true, listaResultado: res.data.listPngBase64 });
            },
            err => {
                this._setLoading(false);
                this._updateResultadoDownloadError(exame, DEFAULT_ERRO_EXAME);
            });
    }

    _getAnexoPDF = (exame, grupo) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarAnexoExamePDF(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);
                if (!res.data || !res.data.pdfBase64) {
                    this._updateAnexoDownloadError(exame, "Retorno sem dados para o anexo do exame.");
                    return;
                }
                this._updateAnexoDownloadError(exame, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if (!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const dtSolicitacao = exame.dtSolicitacao ? new Date(exame.dtSolicitacao) : new Date();
                    const dataSolicitacao = dtSolicitacao.toISOString().split('T')[0].replace(/-/g, '');
                    const soeSeq = exame.soeSeq;
                    const nomeGrupo = exame.nomeGrupoExame;
                    const fileName = `${dataSolicitacao}_${soeSeq}_${nomeGrupo}.pdf`;
                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }

                exame.statusLeituraAnexo = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState(this.state);
            },
            err => {
                this._setLoading(false);
                this._updateAnexoDownloadError(exame, DEFAULT_ERRO_ANEXO);
            });
    }

    _getAnexoPNG = (exame, grupo) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarAnexoExamePNG(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);
                if (!res.data || !res.data.listPngBase64 ||
                    isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length < 1) {
                    this._updateAnexoDownloadError(exame, "Retorno sem dados para o anexo do exame.");
                    this.setState({ mostraAnexo: false, listaAnexo: null });
                    return;
                }
                this._updateAnexoDownloadError(exame, null);

                exame.statusLeituraAnexo = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState({ mostraAnexo: true, listaAnexo: res.data.listPngBase64 });
            },
            err => {
                this._setLoading(false);
                this._updateAnexoDownloadError(exame, DEFAULT_ERRO_ANEXO);
            });
    }

    _getSolicitacaoPDF = (grupo) => {
        this._setLoading(true);

        examesClient.buscarSolicitacaoPDF(
            grupo.soeSeq,
            res => {
                this._setLoading(false);

                if(!res.data.sucesso || !res.data.pdfBase64) {
                    const message = res.data.errorMessage ? res.data.errorMessage : "Retorno sem dados para consulta.";
                    this._updateSolicitacaoDownloadError(grupo, message);
                    return;
                }
                this._updateSolicitacaoDownloadError(grupo, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const dtSolicitacao = grupo.dtSolicitacao ? new Date(grupo.dtSolicitacao) : new Date();
                    const dataSolicitacao = dtSolicitacao.toISOString().split('T')[0].replace(/-/g, '');
                    const soeSeq = grupo.soeSeq;
                    const fileName = `${dataSolicitacao}_${soeSeq}.pdf`;

                    genesysUtils.general.automaticDownloadData(base64, fileName);
                }

                grupo.statusLeituraSolicitacao = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;

                this.setState(this.state);
            },
            err => {
                this._setLoading(false);
                this._updateSolicitacaoDownloadError(grupo, DEFAULT_ERRO_SOLICITACAO);
            });
    }

    _getSolicitacaoPNG = (grupo) => {
        this._setLoading(true);
        examesClient.buscarSolicitacaoPNG(
            grupo.soeSeq,
            res => {
                this._setLoading(false);

                if(!res.data || !res.data.sucesso || !res.data.listPngBase64 || 
                    isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length<1) {
 
                    const message = res.data.errorMessage ? res.data.errorMessage : "Retorno sem dados para consulta.";
                    this._updateSolicitacaoDownloadError(grupo, message);
                    this.setState({ mostraSolicitacao: false, listaSolicitacao: null });
                    return;
                }
                this._updateSolicitacaoDownloadError(grupo, null);

                grupo.statusLeituraSolicitacao = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState({ mostraSolicitacao: true, listaSolicitacao: res.data.listPngBase64 });
            },
            err => {
                this._setLoading(false);
                this._updateSolicitacaoDownloadError(grupo, DEFAULT_ERRO_SOLICITACAO);
            });
    }

    _handleAccordionClick = (e, grupoExames) => {
        grupoExames.active = !grupoExames.active;
        this.setState({});
    }

    _handleClearFilter = () => {
        const { isFiltered } = this.state;
        this.setState({
            isFiltered: false,
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null
        });

        if(isFiltered) {
            this._getListaExames();
        }
    }

    _handleCloseResultado = () => {
        this.setState({
            mostraResutado: false,
            listaResultado: null,
            mostraSolicitacao: false,
            listaSolicitacao: null,
            listaAnexo: null,
            mostraAnexo: false
        });
    }

    _handleKeyDown = (event) => {
        if(this._getDadosVisualizacao()) {
            switch( event.keyCode ) {
                case ESCAPE_KEY:
                    event.stopPropagation();
                    event.preventDefault();
                    this._handleCloseResultado();
                    break;
                default:
                    break;
            }
        }
    }

    _handleUpdateFilter = () => {
        this.setState({
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null
        });
    }

    _isStatusNovo = (statusLeitura) => {
        if (statusLeitura==='NAO_LIDO' || statusLeitura==='ATUALIZACAO_NAO_LIDA') {
            return true;
        }
        return false;
    }

    _openXeroViewer = (url) => {
        const rnIntegration = window.rnIntegration;
        if(!rnIntegration || !rnIntegration.triggerAppOpenURL(url)) {
            genesysUtils.general.automaticAnchorCreateAndClick(url, null, "_blank");
        }
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _updateContadorCompartilhamento = (estudo, add, setTo) => {
        if(!genesysUtils.typeCheck.isInteger(add) && !genesysUtils.typeCheck.isInteger(setTo)) {
            return;
        }
        estudo.contadorCompartilhamento = genesysUtils.typeCheck.isInteger(setTo) ? setTo : (estudo.contadorCompartilhamento + add);
        this.setState({ });
    }

    _updateEstudoViewerError = (estudo, message) => {
        estudo.visualizacaoError = message;
        this.setState(this.state);
    }

    _updateResultadoDownloadError = (exame, message) => {
        exame.downloadError = message;
        this.setState(this.state);
    }

    _updateSolicitacaoDownloadError = (grupo, message) => {
        grupo.downloadError = message;
        this.setState(this.state);
    }

    _updateAnexoDownloadError = (exame, message) => {
        exame.downloadErrorAnexo = message;
        this.setState(this.state);
    }

    componentDidMount(){
        document.addEventListener("keydown", this._handleKeyDown, true);
        this._getListaExames();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mostraResutado, mostraSolicitacao } = this.state;
        if((mostraResutado!==prevState.mostraResutado) || (mostraSolicitacao!==prevState.mostraSolicitacao)) {
            this._appSetBackAction();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown, true);
    }

    render() {
        const { confirmDialog } = this.state;
        return(
            <AppCardModuleBasicWrapper moduleName="exames">

                <Visualizador
                    listaAnexo={this.state.listaAnexo}
                    listaResultado={this.state.listaResultado}
                    listaImages={this._getDadosVisualizacao()}
                    onCloseViewer={this._handleCloseResultado}
                />

                { confirmDialog &&
                    <AppConfirmationDialog
                        title={confirmDialog.title}
                        message={confirmDialog.message}
                        onConfirm={confirmDialog.onConfirm}
                        onCancel={confirmDialog.onCancel} />
                }

                <AppDateFilter
                    filterError={this.state.filterError}
                    onFilterClear={this._handleClearFilter}
                    onFilterUpdate={this._handleUpdateFilter}
                    onFilter={this._getListaExames}
                />

                {this.state.searchErrorMessage &&
                    <AppMessageBox
                        id="msg-exames-error"
                        className="error"
                        messageData={{ message: this.state.searchErrorMessage }} />
                }

                {this.state.searchInfoMessage &&
                    <AppMessageBox
                        id="msg-exames-information"
                        className="information"
                        messageData={{ message: this.state.searchInfoMessage }} />
                }

                <div className="results-section">
                    <Accordion fluid styled>
                        {this.state.searchResult.map((grupo, indexGrupo) => {
                            const serviceConfig = getAppServiceSettingsByName(this.props.appContextConfig, APP_SERVICE_LIST.EXAMES) || {};
                            const exibirTicketSolicitacao = grupo.exibeTicketSolicitacao;
                            const habilitaDownload = grupo.habilitaDownloadTicketSolicitacao;
                            const habilitarEstudos = serviceConfig && serviceConfig.estudoEnabled;
                            const exibeSituacao = grupo.situacao && grupo.situacao.toUpperCase()==="CA";
                            const exibirResultados = grupo.resultados && grupo.resultados.length>0;
                            const exibirEstudos = habilitarEstudos && grupo.estudosPACS && grupo.estudosPACS.length>0;
                            const indicativoNovoGrupo = this._isStatusNovo(grupo.statusLeituraGrupo);
                            const existeResultadoNovo = this._existsStatusNovo(grupo.resultados, "statusLeituraResultado");
                            const existeEstudoNovo = this._existsStatusNovo(grupo.estudosPACS, "statusLeituraEstudo");
                            const existeAnexo = this._existsAnexoSolicitacao(grupo.resultados);

                            return (
                                <div
                                    key={'grupoExameId_' + indexGrupo}
                                    className={`accordion-item${grupo.active ? " active": ""}${indicativoNovoGrupo ? " emphasis" : ""}`}
                                >
                                    <Accordion.Title onClick={(e) => this._handleAccordionClick(e, grupo)}>
                                        <div className="title-info-wrapper">
                                            { indicativoNovoGrupo &&
                                                <div className="indicativo-novo bold-text">[NOVO]</div>
                                            }
                                            <div className="information no-wrap">
                                                <div className="data bold-text separator"><Moment format="DD/MM/YYYY" >{grupo.dtSolicitacao}</Moment></div>
                                                <div className="solicitacao">Nº Solicit.: <span className="bold-text">{grupo.soeSeq}</span></div>
                                            </div>
                                        </div>
                                        <div className="collapsible-wrapper">
                                            <AccordionCollapsible active={grupo.active} iconVariant={indicativoNovoGrupo ? "new" : null} />
                                        </div>

                                    </Accordion.Title>
                                    <Accordion.Content>
                                        { exibirTicketSolicitacao &&  /* === SOLICITACAO === */
                                            <SectionSolicitacao
                                                grupo={grupo}
                                                checkStatusNovo={this._isStatusNovo}
                                                exibeSituacao={exibeSituacao}
                                                habilitaDownload={habilitaDownload}
                                                onView={this._getSolicitacaoPNG}
                                                onDownloadPdf={this._getSolicitacaoPDF}
                                            />
                                        }

                                        { exibirResultados &&  /* === RESULTADOS === */
                                        <SectionResultados 
                                            groupIndex={indexGrupo}
                                            resultados={grupo.resultados}
                                            existeResultadoNovo={existeResultadoNovo}
                                            checkStatusNovo={this._isStatusNovo}
                                            onView={(exame) => this._getResultadoPNG(exame, grupo)}
                                            onDownloadPdf={(exame) => this._getResultadoPDF(exame, grupo)}
                                            existeExameNaoLiberado={grupo?.dadosSolicitacao?.existeExameNaoLiberado}
                                        />
                                        }

                                        { exibirEstudos && /* === ESTUDOS === */
                                        <SectionEstudos
                                            groupIndex={indexGrupo}
                                            estudosPACS={grupo.estudosPACS}
                                            existeEstudoNovo={existeEstudoNovo}
                                            enableShare={isAppServiceEnabled(this.props.appContextConfig, APP_SERVICE_LIST.COMPARTILHAR_ESTUDO)}
                                            enableRevokeShare={serviceConfig.estudoRevogarCompartilhamentoEnabled}
                                            checkStatusNovo={this._isStatusNovo}
                                            onView={(estudo) => this._estudoAbrirConfirmacao(estudo, grupo)}
                                            onShare={this._estudoCompartilhar}
                                            onRevokeShare={this._estudoRevogarCompartilhamentoConfirmacao}
                                        />
                                        }

                                        {existeAnexo && /* === ANEXOS === */
                                        <SectionAnexos 
                                            groupIndex={indexGrupo}
                                            resultados={grupo.resultados} 
                                            checkStatusNovo={this._isStatusNovo}
                                            onView={(exame) => this._getAnexoPNG(exame, grupo)}
                                            onDownloadPdf={(exame) => this._getAnexoPDF(exame, grupo)}
                                        />
                                        }

                                    </Accordion.Content>
                                </div>
                            )
                        })}
                    </Accordion>
                    <div className="padding-rodape"></div>
                </div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default Exames;