/*
** @name: Meu Clínicas - notificationManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Abril 2022
** @description: Prove rotinas para para obterção notificações dos servicços para o usuário
**
*/

import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import sessionStorageManager from './sessionStorageManager.js';

import notificationClient from '../apiClients/notification/notificationClient.js';


class NotificationManager {

    async asyncUserNotifications(pacCodigo) {
        return new Promise(async (resolve, reject) => {
            // request user's notifications list
            notificationClient.noticesList(
                pacCodigo,
                res => resolve(res.data),
                err => console.error(`Error obtaining list of notifications:`, err)
            );
        });
    }

    async registerPushDeviceAsync(user, forceUpdate) {
        const { userId, pacCodigo, nome } = user || {};
        return new Promise(async (resolve, reject) => {
            const rnIntegration = window.rnIntegration;
            const { pushNotification } = (genesysUtils.deviceCheck.isMobile() && rnIntegration && rnIntegration.isAppRunning()) ? rnIntegration.getDevicePermissions() : {};
            if(userId && pushNotification && pushNotification.allowed) {
                const { mobilePlatform, expoDeviceToken, nativeDeviceToken } = pushNotification;
                const payload = { userId, pacCodigo, nome, mobilePlatform, expoDeviceToken, nativeDeviceToken, forceUpdate };
                notificationClient.pushDeviceRegister(
                    payload,
                    res => { resolve(res) },
                    err => { reject(err) }
                );
            } else {
                reject();
            }
        });
    }

    notifications = {
        getData: () => {
            const user = sessionStorageManager.auth.getUserData();
            return user ? user.notifications : null;
        },

        getPushUserConflict: () => {
            const user = sessionStorageManager.auth.getUserData();
            return user ? user.pushUserConflict : null;
        },

        setData: (authContext, data) => {
            const userUpdate = sessionStorageManager.auth.getUserData();
            if(!authContext || !userUpdate) {
                return;
            }
            // Update data and store
            userUpdate.notifications = data ? data : null;
            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), userUpdate);
            authContext.methods.refresh();
            return userUpdate;
        },

        setPushUserConflict: (authContext, data) => {
            const userUpdate = sessionStorageManager.auth.getUserData();
            if(!authContext || !userUpdate) {
                return;
            }

            // Update user data and store
            userUpdate.pushUserConflict = data ? data : null;
            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), userUpdate);
            authContext.methods.refresh();
            return userUpdate;
        }
    }
}

const notificationManager = new NotificationManager();
export default notificationManager;