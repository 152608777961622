import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";
import moment from "moment";

import { apiUrl } from '@hcpa-react-components/api-url-builder';


const API_PORT = 8083;
const API_SERVICE = 'regmedicos-service';
const API_VERSION = 'v2';

class RegistrosMedicosClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    } 

    buscarListaEtiquetasMateriais(pacCodigo, dtInicial, dtFinal, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
        };

        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/etiquetas-materiais/lista'),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarPDFEtiquetasMateriais(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/etiquetas-materiais/${id}/pdf`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarPNGEtiquetasMateriais(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/etiquetas-materiais/${id}/png`),
            successCallback,
            errorCallback,
            queryParams);
    }


    buscarListaLaudosAtestados(pacCodigo, dtInicial, dtFinal, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
        };

        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/laudos-atestados/lista'),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarLaudosAtestadosPDF(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/laudos-atestados/${id}/pdf`),
            successCallback,
            errorCallback,
            queryParams);
    }

    indicarLeituraLaudosAtestados(id, pacCodigo, successCallback, errorCallback) { 
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/laudos-atestados/leitura?id=${id}&pacCodigo=${pacCodigo}`),
            successCallback,
            errorCallback
        );
    }

    /* Servico - Validação */
    buscarPDFLaudosAtestadosValidado(requestData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/pdf'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    validarLaudosAtestados(requestData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/validar'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    buscarLaudosLmePNG(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/laudosatestados-lme/${id}/png`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarLaudosLmePDF(id, pacCodigo, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/laudosatestados-lme/${id}/pdf`),
            successCallback,
            errorCallback,
            queryParams);
    }



}

const registrosMedicosClient = new RegistrosMedicosClient();
export default registrosMedicosClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};