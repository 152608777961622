/*
** @name: Meu Clínicas - app
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021 - Refeito para novo layout da aplicação
** @description: Módulo principal da aplicação realiza validações de pre-requisitos (cookies, browsers, ...)
** Prove os contextos de autenticação e themas e chama a 'appController'
**
** @date: Outubro 2021
** @description: Adicionado biblioteca de JavaScripts externa para uso na aplicação
**
** @update: Novembro 2022
** @description: Adicionado teste para evitar chamada por 'iPhone home shortcut' por causa de problemas de renderização
**
** @update: Agosto 2024
** @description: Alterada chamadas das paginas de erro para centralizar onde se obter configuracao da aplicação
** @description: Realizada a validação dos serviços requeridos na incialização
*/


import React, { Component } from 'react';
import { browserName, browserVersion, isAndroid, isIOS, isChrome, isFirefox, isSafari, isMobile, isTablet } from 'react-device-detect';
import { AppConfigContextProvider, isCustomConfigurationApplied } from '@hcpa-react-components/app-customization';
import { appendScript, removeScript } from '@hcpa-react-components/script-handler';
import 'semantic-ui-css/semantic.min.css';
import './scss/app.scss';  // In app.js styles must be imported before include other modules due to override order

import utils from '../../core/utils';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { asyncBuildAppConfiguration, createAppBasicConfiguration } from '../../core/appConfiguration.js';
import { AuthContextProvider } from '../../core/authContext.js';
import { appCacheImages } from '../../core/imageCacheManager.js';
import { getAppServiceSettingsByName } from '../../core/appSpecificConfigHandler.js';
import { APP_REQUIRED_SERVICES } from '../../core/appServiceList.js';

import AppController from '../../components/general/appController/appController.js';
import AppInitializing from '../../components/general/appInitializing/appInitializing.js';
import AppLoading from '../../components/general/appLoading/appLoading.js';
import ErrorPage from '../../components/general/appErrorPage/appErrorPage.js';

import configurationClient from '../../apiClients/login/configurationClient.js';


// Import Fonts
import './appFonts.js';

// Import theme custom modules styles
import '../../theme/themeCustomStyles.js';


const isChromeWebView = (browserName && browserName.toLowerCase() === "chrome webview");
const isWebKit = (browserName && browserName.toLowerCase() === "webkit");

class App extends Component {

    constructor(props) {
        super(props);

        this.imageCacheWrapper = React.createRef();
        this.state = {
            applConfig: createAppBasicConfiguration(),
            initializationReady: false
        };
    }

    _asycConfigurationUpdate = async (customization) => {
        const newConfig = await asyncBuildAppConfiguration(customization);
        sessionStorageManager.customConfig.store(customization);
        this.setState({ 
            applConfig: newConfig,
            initializationReady: true
        });
    }

    _initialize = async () => {
        const applConfig = await asyncBuildAppConfiguration(sessionStorageManager.customConfig.get());

        // validate service configuration
        Object.keys(APP_REQUIRED_SERVICES).forEach((key) => {
            const serviceName = APP_REQUIRED_SERVICES[key];
            if(!getAppServiceSettingsByName(applConfig, serviceName)) {
                throw new Error(`Required service '${serviceName}' in module 'appServiceList' not found in application config file.`);
            }
        });

        // force imagens preload to cache it
        await appCacheImages(applConfig, this.imageCacheWrapper.current);
     
        // Load custom frontend parameters
        if(isCustomConfigurationApplied(applConfig)) {
            this.setState({ 
                applConfig: applConfig,
                initializationReady: true 
            });
        } else {
            // Load, asynchronously, the custom frontend parameters
            configurationClient.asyncObterParametrosFrontend()
                .then(res => this._asycConfigurationUpdate(res.data))
                .catch(err => { // ignore error and finalize initialization
                    this.setState({ initializationReady: true });
                });
        }
    }

    _isHomeShortCut = () => {
        return isIOS && isMobile && window.navigator && window.navigator.standalone ? true : false;
    }

    _isValidBrowser = () => {
        const browserMajorVersion = parseInt(browserVersion.split('.')[0]);
        const isNotMobile = !(isMobile || isTablet);
        if (isSafari && browserMajorVersion >= 10) {
            return true;
        }
        if (isChrome && browserMajorVersion >= 64) {
            return true;
        }
        if(isFirefox) {
            if ((isNotMobile || isAndroid) && browserMajorVersion >= 69) {
                return true;
            }
            if ((isMobile || isTablet) && isIOS && browserMajorVersion >= 22) {
                return true;
            }
        }
        if (isChromeWebView || isWebKit) {
            return true;
        }
        return false;
    }

    /* Events and Component Render */
    componentDidMount() {
        // adicionar javascripts externos para uso em varios modulos da aplicacao
        const appPublicUrl = utils.getPublicPath(true);
        appendScript(`${appPublicUrl}/scripts/ics.js/ics.deps.min.js`);
        appendScript(`${appPublicUrl}/scripts/jitsi/external_api.min.js`);

        // Initialize
        setTimeout(() => this._initialize(), 1);
    }

    componentWillUnmount() {        
        // remover javascripts externos
        const appPublicUrl = utils.getPublicPath(true);
        removeScript(`${appPublicUrl}/scripts/ics.js/ics.deps.min.js`);
        removeScript(`${appPublicUrl}/scripts/jitsi/external_api.min.js`);
    }

    render() {
        const { applConfig, initializationReady } = this.state;

        if(this._isHomeShortCut()) {
            return <ErrorPage appConfiguration={applConfig} page="home-shortcut-not-allowed" />
        }
        if(!this._isValidBrowser()) {
            return <ErrorPage appConfiguration={applConfig} page="not-supported-browser" />;
        }
        if(!sessionStorageManager.isCookiesEnabled()) {
            return <ErrorPage appConfiguration={applConfig} page="cookies-not-enabled" />;
        }

        return (
            <>
                { !initializationReady && 
                <AppInitializing>
                    <AppLoading config={applConfig} />
                </AppInitializing> }

                { initializationReady &&
                <AppConfigContextProvider config={applConfig}>
                    <AuthContextProvider>

                        <AppController />

                    </AuthContextProvider>
                </AppConfigContextProvider> }
                <div ref={this.imageCacheWrapper} className="app-cached-image-wrapper"></div>
            </>
        );
    }
} export default App;
