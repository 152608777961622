/*
** @name: Meu Clínicas - editarDados
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Maio 2021
** @description: Módulo para permitir a edição de dados do paciente assim como correção de dados
** para usuários cadastrados que ainda não foram confirmados
**
** @update: Abril 2023 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Alterado para não mais remover userId após um update do usuário
*/

import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import SmsValidationModal from '../../components/general/smsValidationModal/smsValidationModal.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';


// Import module styles
import './editarDados.scss'; 


const CPF_MASK = [/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '-', /\d/, /\d/];
const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/, /\d/, /\d/];
const PHONE_MASK = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const EditarDados = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    return(
        <EditarDadosImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class EditarDadosImplem extends Component {
    constructor(props) {
        super(props);

        const user = props.authContext.properties.user;
        const permissions = this._getFieldUpdatePermissions(user);
        this.state = {
            unconfirmedUserMode: !props.authContext.methods.isAuthenticated(),
            updateError: null,
            updateSuccess: null,
            showSmsValidation: false,
            updateConfirmation: null,
            forceLogout: false,
            form: {
                fields: {
                    nomeCompleto: {
                        disabled: !permissions.nomeCompleto,
                        backendName: 'nome',
                        errorMessage: null,
                        regex: /^[a-zà-ýA-ZÀ-Ý]+\s?([a-zà-ýA-ZÀ-Ý]\s?)*$/,
                        maxSize: 75
                    },
                    cpf: {
                        disabled: !permissions.cpf,
                        backendName: 'cpf',
                        errorMessage: null
                    },
                    rg: {
                        disabled: !permissions.rg,
                        backendName: 'rg',
                        errorMessage: null,
                        maxSize: 20
                    },
                    dataNascimento: {
                        disabled: !permissions.dataNascimento,
                        backendName: 'dtNascimento',
                        errorMessage: null
                    },
                    celular: {
                        disabled: !permissions.celular,
                        backendName: 'numCelular',
                        errorMessage: null
                    },
                    email: {
                        disabled: !permissions.email,
                        backendName: 'email',
                        errorMessage: null,
                        maxSize: 254
                    },
                    emailConfirmar: {
                        disabled: !permissions.email,
                        backendName: 'emailConfirmar',
                        errorMessage: null,
                        maxSize: 254
                    }
                },
                fieldToFocus: 'nomeCompleto'
            },
            smsValidationTokenInfo: {}
        }

        this.afterErrorMsgRef = React.createRef();
        this._resetFormFieldsValues(user, this.state.form);
    }

    _appSetBackAction(params) {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            const { smsValidation } = params || {};
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(smsValidation) {
                rnIntegration.backAction.push(() => {
                    this._handleValidationClose();
                });
            }
        }
    }

    _clearErrors = () => {
        let form = this.state.form;

        form.fieldToFocus = false;
        for (var prop in form.fields) {
            if (Object.prototype.hasOwnProperty.call(form.fields, prop)) {
                form.fields[prop].errorMessage = null;
            }
        }

        this.setState({
            form, 
            updateError: null,
            updateSuccess: null
        });
    }

    _focusFormField = () => {
        if(!this.state.form.fieldToFocus) {
            return;
        }

        let l_obj = document.getElementsByName(this.state.form.fieldToFocus);
        if(l_obj.length > 0) {
            let obj = l_obj[0];
            obj.focus();

            let form = this.state.form;
            form.fieldToFocus = false;
            this.setState({
                form: form
            });
        }
    }

    _getFieldClasses = (fieldName) => {
        let classes = "";
        const field = this.state.form.fields[fieldName];

        classes += field.value ? 'has-content' : 'empty';
        classes += field.errorMessage ? ' invalid' : ' valid';

        return classes;
    }

    _getFieldUpdatePermissions = (user) => {
        const permissions = {};

        if(user) {
            permissions.nomeCompleto = true;
            permissions.rg = true;
            permissions.dataNascimento = user.pacCodigo || user.localizador ? false : true;
            permissions.celular = true;
            permissions.email = true;
        }

        return permissions;
    }

    _getFormValuesToSubmit = () => {
        const submitValues = {};
        const form = this.state.form;
        const user = this.props.authContext.properties.user;
        const permissions = this._getFieldUpdatePermissions(user);
        const smsValidationTokenInfo = genesysUtils.typeCheck.isObject(this.state.smsValidationTokenInfo) ? this.state.smsValidationTokenInfo : {};

        for(var prop in form.fields) {
            if(permissions[prop] && this._isFieldChanged(prop) && !genesysUtils.array.inArray(prop, ["emailConfirmar"])) {
                const field = form.fields[prop];
                let value = field.value;
                if(prop==="dataNascimento") {
                    value = utils.convertDateToEpochAtMidday(value);
                }
                submitValues[field.backendName] = value;
            }
        }

        // Adicionar confirmações se for o caso
        if(Object.prototype.hasOwnProperty.call(submitValues, "email")) {
            submitValues.emailConfirmar = form.fields.emailConfirmar.value;
        }

        // Adicionar dados da validacao do celular
        submitValues.smsToken = smsValidationTokenInfo.confirmed ? smsValidationTokenInfo.typedToken : null;
        submitValues.smsTokenId = smsValidationTokenInfo.confirmed ? smsValidationTokenInfo.tokenId : null;

        // Adicionar dados autenticação se necessário (usuário sem token de login)
        if(this.state.unconfirmedUserMode) {
            submitValues.specificJwtToken = user.specificUseDataUpdateToken;
            submitValues.fingerprint = sessionStorageManager.auth.getFingerprint();
            submitValues.userId = user.userId;
        }

        return submitValues;
    }

    _handleAlterar = (e) => {
        e.stopPropagation();
        e.preventDefault(); 

        this._clearErrors();

        const confirmationMessage = this._isFieldChanged("email") ?
            "Você solicitou alteração do e-mail. Isso bloqueará seu acesso até confirmar o novo e-mail pelo link que será enviado. Confirma alterações?" :
            "Confirma as alterações dos dados?";
        const onConfirm = (this._isFieldChanged("celular") && this._isValidCelular() && !this._isCelularValidated()) ? 
                    () => this._handleValidationShow() : () => this._processaAtualizacao();
        this.setState({
            updateConfirmation: {
                message: confirmationMessage,
                onConfirm: () => { this._handleConfirmationClose(); onConfirm() },
                onCancel: () => this._handleConfirmationClose()
            }
        });
    }

    _handleConfirmationClose = () => {
        this.setState({ 
            updateConfirmation: null 
        });
    }

    _handleChange = (e, { name, value }) => {
        const updatedForm = this.state.form;
        const disabled = updatedForm.fields[name].disabled;
        const fieldRegex = updatedForm.fields[name].regex;
        const maxSize = updatedForm.fields[name].maxSize;

        if(disabled || (value && fieldRegex && !fieldRegex.test(value)) ||
            (value && maxSize && value.length > maxSize)) {
            return;
        }

        if(name === 'nomeCompleto') {
            updatedForm.fields[name].value = value ? value.toUpperCase() : "";
        } else {
            updatedForm.fields[name].value = value;
        }

        updatedForm.fields[name].errorMessage = null;
        updatedForm.fieldToFocus = false;

        this.setState({ 
            form: updatedForm, 
            updateError: null,
            updateSuccess: null,
            smsValidationTokenInfo: (name === 'celular') ? { } : this.state.smsValidationTokenInfo
        });
    }

    _handleForcedLogout = () => {
        this.props.appControllerContext.methods.doLogout(true);
    }

    _handleValidationClose = (keepTypedToken) => {
        const updateTokenInfo = this.state.smsValidationTokenInfo;
        if(!keepTypedToken) updateTokenInfo.typedToken = null;
        this.setState({ 
            showSmsValidation: false,
            smsValidationTokenInfo: updateTokenInfo
        });
        this._appSetBackAction({ smsValidation: false });
    };
    
    _handleValidationShow = () => {
        this.setState({ showSmsValidation: true });
        this._appSetBackAction({ smsValidation: true });
    }

    _handleValidationTokenComplete = () => {
        const { smsValidationTokenInfo } = this.state;
        if(smsValidationTokenInfo.confirmed) {
            this._handleValidationClose(true);
            this._processaAtualizacao();
        }
    }

    _handleValidationTokenUpdate = (newTokenInfo) => {
        this.setState({
            smsValidationTokenInfo: genesysUtils.typeCheck.isObject(newTokenInfo) ? newTokenInfo : this.state.smsValidationTokenInfo
        })
    }

    _isFieldChanged = (fieldName) => {
        const form = this.state.form;
        if(Object.prototype.hasOwnProperty.call(form.fields, fieldName)) {
            const field = form.fields[fieldName];
            if(field.value !== field.originalValue) {
                return true;
            }
        }
        return false;
    }

    _isFormChanged = () => {
        const form = this.state.form;

        for (var prop in form.fields) {
            if(this._isFieldChanged(prop)) {
                return true;
            }
        }
        return false;
    }

    _isCelularValidated = () => {
        const smsValidationTokenInfo = genesysUtils.typeCheck.isObject(this.state.smsValidationTokenInfo) ? this.state.smsValidationTokenInfo : {};
        return smsValidationTokenInfo.tokenId && smsValidationTokenInfo.typedToken && smsValidationTokenInfo.confirmed;
    }

    _isValidCelular = () => {
        const fields = this.state.form.fields;
        return /^\(\d{2}\) 9\d{4}-\d{4}$/.test(fields.celular.value);
    }

    _processaAtualizacao = () => {
        this._setLoading(true);

        const userUpdateApiClient = this.state.unconfirmedUserMode ? usuarioClient.atualizarUsuarioNoAuth : usuarioClient.atualizarUsuario;
        userUpdateApiClient(
            this._getFormValuesToSubmit(),
            res => {
                this._setLoading(false);
                this._updateUserData(res.data.updatedFields);
                this.setState({
                    smsValidationTokenInfo: {},
                    updateSuccess: {
                        header: "Ok",
                        message: "Alteração dos dados foi efetivada"
                    }
                });
            },
            err => {
                this._setLoading(false);
                if(err.response && (err.response.data || err.response.status === 403)) {
                    if(this._updateGeneralErrors(err.response)) {
                        return;
                    }

                    if(this._updateFormErrors(err.response)) {
                        return;
                    }
                }

                this.setState({
                    updateError: {
                        header: "Ops!",
                        message: "Ocorreu um erro ao processar sua requisição"
                    }
                });
            }
        );
    }

    _resetFormFieldsValues = (user, form) => {
        const updateForm = form ? form : this.state.form;
        const updateField = (name, value) => {
            updateForm.fields[name].value = value;
            updateForm.fields[name].originalValue = value;
        }
        const ifNull = (value, nullValue) => {
            return value===null ? nullValue : value;
        }

        updateField('nomeCompleto', ifNull(user.nome, ""));
        updateField('cpf', ifNull(user.cpf, ""));
        updateField('rg', ifNull(user.rg, ""));
        updateField('dataNascimento', ifNull(user.dtNascimento, ""));
        updateField('celular', ifNull(user.celular, ""));
        updateField('email', ifNull(user.email, ""));
        updateField('emailConfirmar', '');

        if(!form) {
            this.setState({ form: updateForm });
        }
    }

    _scrollToMessageDiv = () => {
        if(this.afterErrorMsgRef.current){
            this.afterErrorMsgRef.current.scrollIntoView({block: "end", behavior: "smooth"})
        }
    }

    _updateFormErrors = (response) => {
        if(!response || response.status !== 400 || 
            !response.data || !response.data.validatorResponse || !response.data.validatorResponse.errors) {
            return false;
        }

        var hasErros = false;
        var alreadyFocused = false;
        const form = this.state.form;
        const fieldErrors = response.data.validatorResponse.errors;
        for(var prop in form.fields) {
            if(Object.prototype.hasOwnProperty.call(form.fields, prop)) {
                let formField = form.fields[prop];

                if(fieldErrors[formField.backendName]) {
                    hasErros = true;
                    formField.errorMessage = fieldErrors[formField.backendName];
                    if(!alreadyFocused) {
                      form.fieldToFocus = prop;
                      alreadyFocused = true;
                    }
                } else {
                    formField.errorMessage = null;
                }
            }
        }

        let errMsg = null;
        let updateTokenInfo = this.state.smsValidationTokenInfo;
        if(fieldErrors["smsToken"]) {
            hasErros = true;
            errMsg = fieldErrors["smsToken"];
            updateTokenInfo = {};
        }

        this.setState({ form: form });
        if(hasErros) {
            this.setState({
                smsValidationTokenInfo: updateTokenInfo,
                updateError: {
                    header: "Ops!",
                    message: errMsg ? errMsg : "Verifique o preenchimento dos campos do formulário"
                }
            });
            return true;
        }
        return false;
    }

    _updateGeneralErrors = (response) => {
        if(response && response.status === 403) {
            this.setState({
                updateError: {
                    header: "Atenção",
                    message: "Permissão inválida ou expirada para operação solicitada."
                }
            });
            return true;
        }
        if(response && response.status === 406) {
            this.setState({
                updateError: {
                    header: "Atenção",
                    message: "Você deve alterar ao menos um dado para realizar atualização."
                }
            });
            return true;
        }
        return false;
    }

    _updateUserData = (updatedFields) => {
        if(genesysUtils.typeCheck.isObject(updatedFields) && Object.keys(updatedFields).length>0) {
            const { authContext } = this.props;
            const user = authContext.properties.user;
            const forceLogout = this._isFieldChanged("email") || this.state.unconfirmedUserMode;

            user.nome = updatedFields.nome ? updatedFields.nome : user.nome;
            user.rg = updatedFields.rg ? updatedFields.rg : user.rg;
            user.dtNascimento = updatedFields.dtNascimento ? updatedFields.dtNascimento : user.dtNascimento;
            user.celular = updatedFields.numCelular ? updatedFields.numCelular : user.celular;
            user.email = updatedFields.email ? updatedFields.email : user.email;

            user.specificUseDataUpdateToken = null;

            sessionStorageManager.auth.store(sessionStorageManager.auth.getToken(), sessionStorageManager.auth.getFingerprint(), user);
            authContext.methods.refresh();

            this._resetFormFieldsValues(user);
            this.setState({ forceLogout: forceLogout });
        }
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    Render_EditForm = () => {
        const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
        const fields = this.state.form.fields;

        return (
            <>
                <Form className="edit-form">
                    <div className="float-label-field nome-completo">
                        <Input 
                            fluid
                            id="input-nome-completo"
                            name="nomeCompleto"
                            disabled={fields.nomeCompleto.disabled}
                            className={`input-wrapper ${this._getFieldClasses("nomeCompleto")}`}
                            onChange={(e) => this._handleChange(e, {name: "nomeCompleto", value: e.target.value})}
                            placeholder=""
                            autoComplete="name"
                            label="Nome completo"
                            labelPosition="right"
                            value={fields.nomeCompleto.value}
                        />                       
                        {
                            fields.nomeCompleto.errorMessage &&
                            <div className="field-error" id="msg-error-nome">
                                {fields.nomeCompleto.errorMessage}
                            </div>
                        }
                    </div>

                    <div className="float-label-field cpf">
                        <Input
                            fluid
                            disabled={fields.cpf.disabled}
                            children={
                                <div className={`masked-input-wrapper ${this._getFieldClasses("cpf")}`}>
                                    <MaskedInput
                                        id="input-cpf"
                                        name="cpf"
                                        disabled={fields.cpf.disabled}
                                        onChange={(e) => this._handleChange(e, {name: "cpf", value: e.target.value})}
                                        keepCharPositions="true"
                                        mask={CPF_MASK}
                                        autoComplete="cpf"
                                        value={fields.cpf.value}
                                    />
                                    <div className="ui label label">CPF</div>
                                </div>
                            }
                        />
                        {
                            fields.cpf.errorMessage &&
                            <div className="field-error" id="msg-error-cpf">
                                {fields.cpf.errorMessage}
                            </div>
                        }
                    </div>

                    <div className="float-label-field rg">
                        <Input
                            fluid
                            id="input-rg"
                            name="rg"
                            disabled={fields.rg.disabled}
                            className={`input-wrapper ${this._getFieldClasses("rg")}`}
                            onChange={(e) => this._handleChange(e, {name: "rg", value: e.target.value})}
                            placeholder=""
                            autoComplete="rg"
                            label="RG"
                            labelPosition="right"
                            value={fields.rg.value}
                        />
                        {
                            fields.rg.errorMessage &&
                            <div className="field-error" id="msg-error-rg">
                                {fields.rg.errorMessage}
                            </div>
                        }
                    </div>

                    <div className="float-label-field data-nascimento">
                        <Input
                            fluid
                            disabled={fields.dataNascimento.disabled}
                            children={
                                <div className={`masked-input-wrapper ${this._getFieldClasses("dataNascimento")}`}>
                                    <MaskedInput
                                        id="input-data-nascimento"
                                        name="dataNascimento"
                                        disabled={fields.dataNascimento.disabled}
                                        className=""
                                        onChange={(e) => this._handleChange(e, {name: "dataNascimento", value: e.target.value})}
                                        keepCharPositions="true"
                                        pipe={autoCorrectedDatePipe}
                                        mask={DATE_MASK}
                                        placeholder="dd/mm/aaaa"
                                        autoComplete="bday"
                                        value={fields.dataNascimento.value}
                                    />
                                    <div className="ui label label">Data de nascimento</div>
                                </div>
                            }
                        />
                        {
                            fields.dataNascimento.errorMessage &&
                            <div className="field-error" id="msg-error-data-nascimento">
                                {fields.dataNascimento.errorMessage}
                            </div>
                        }
                    </div>

                    <div className="float-label-field celular">
                        <Input
                            fluid
                            disabled={fields.celular.disabled}
                            children={
                                <div className={`masked-input-wrapper ${this._getFieldClasses("celular")}`}>
                                    <MaskedInput
                                        id="input-celular"
                                        name="celular"
                                        disabled={fields.celular.disabled}
                                        className=""
                                        onChange={(e) => this._handleChange(e, {name: 'celular', value: e.target.value})}
                                        keepCharPositions="true"
                                        mask={PHONE_MASK}
                                        placeholder="(__) _____-___"
                                        autoComplete="tel-national"
                                        value={fields.celular.value}
                                    />
                                    <div className="ui label label">Telefone celular</div>
                                </div>
                            }
                        />
                        {
                            fields.celular.errorMessage &&
                            <div className="field-error" id="msg-error-celular">
                                {fields.celular.errorMessage}
                            </div>
                        }
                    </div>

                    <div className="float-label-field email">
                        <Input
                            fluid
                            id="input-email"
                            name="email"
                            disabled={fields.email.disabled}
                            className={`input-wrapper ${this._getFieldClasses("email")}`}
                            onChange={(e) => this._handleChange(e, {name: "email", value: e.target.value})}
                            placeholder=""
                            autoComplete="email"
                            label="E-mail"
                            labelPosition="right"
                            value={fields.email.value}
                        />
                        {
                            fields.email.errorMessage &&
                            <div className="field-error" id="msg-error-email">
                                {fields.email.errorMessage}
                            </div>
                        }
                    </div>

                    { this._isFieldChanged("email") &&
                    <div className="float-label-field email-confirmar">
                        <Input
                            fluid
                            id="input-email-confirmar"
                            name="emailConfirmar"
                            disabled={fields.emailConfirmar.disabled}
                            className={`input-wrapper ${this._getFieldClasses("emailConfirmar")}`}
                            onChange={(e) => this._handleChange(e, {name: "emailConfirmar", value: e.target.value})}
                            placeholder=""
                            autoComplete="off"
                            label="Confirmar o e-mail"
                            labelPosition="right"
                            value={fields.emailConfirmar.value}
                            onPaste={e => e.preventDefault() }
                            onDrop={e => e.preventDefault() }
                        />
                        {
                            fields.emailConfirmar.errorMessage &&
                            <div className="field-error" id="msg-error-email-confirmar">
                                {fields.emailConfirmar.errorMessage}
                            </div>
                        }
                    </div>
                    }

                    { this.state.updateError &&
                    <AppMessageBox 
                        id="msg-update-error" 
                        className="error" 
                        messageData={this.state.updateError} />
                    }

                    { this.state.updateSuccess &&
                    <AppMessageBox 
                        id="msg-editar-dados-success" 
                        className="information"
                        messageData={this.state.updateSuccess} />
                    }

                    <div ref={this.afterErrorMsgRef} className="main-action">
                        <button
                            id="button-alterar"
                            type="default"
                            disabled={!this._isFormChanged()}
                            className="app-form-button"
                            onClick={(e) => this._handleAlterar(e)}
                        >
                            ALTERAR
                        </button>
                    </div>
                </Form>
            </>
        );
    }

    componentDidMount() {
        this._focusFormField();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.updateError) {
            this._scrollToMessageDiv()
        }

        this._focusFormField();
    }

    render() {
        const { forceLogout, updateConfirmation, showSmsValidation, smsValidationTokenInfo } = this.state;
        const { fields } = this.state.form;
        const user = this.props.authContext.properties.user;

        return(
            <AppCardModuleBasicWrapper moduleName="editar-dados">

                { forceLogout &&
                <AppConfirmationDialog
                    title="Atenção"
                    message="Dados alterados com sucesso. Para seguir utilizando a aplicação você deve validar o seu e-mail."
                    hideCancelButton={true}
                    onConfirm={() => this._handleForcedLogout()}
                    onCancel={() => this._handleForcedLogout()}
                />
                }

                { updateConfirmation &&
                <AppConfirmationDialog
                    title="Confirmação"
                    message={updateConfirmation.message}
                    onConfirm={updateConfirmation.onConfirm}
                    onCancel={updateConfirmation.onCancel} />
                }

                { showSmsValidation && 
                <SmsValidationModal 
                    allowSkip={false}
                    cpf={user.cpf}
                    numCelular={fields.celular.value} 
                    smsActivationToken={smsValidationTokenInfo}
                    onTokenChange={this._handleValidationTokenUpdate}
                    onComplete={this._handleValidationTokenComplete}
                    onCancel={this._handleValidationClose} />
                }

                { this.Render_EditForm() } 

            </AppCardModuleBasicWrapper>
        );
    }
}

export default EditarDados;
