import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";
import moment from "moment";

import { apiUrl } from '@hcpa-react-components/api-url-builder';

const API_PORT = 8085;
const API_SERVICE = 'receitas-service';
const API_VERSION = 'v2';

class ReceitasClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    } 

    buscarListaReceitas(pacCodigo, dtInicial, dtFinal, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
        };

        if (dtInicial) {            
            queryParams.dataInicial = moment(dtInicial, 'DD/MM/YYYY').toISOString(true);
        }
        
        if (dtFinal) {
            queryParams.dataFinal = moment(dtFinal, 'DD/MM/YYYY').toISOString(true);
        }   

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/receitas/lista'),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarPDF(id, pacCodigo, grupoId, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
            grupoId: grupoId
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/receitas/${id}/pdf`),
            successCallback,
            errorCallback,
            queryParams);
    }

    buscarReceita(id, pacCodigo, grupoId, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
            grupoId: grupoId
        };

        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/receitas/${id}`),
            successCallback,
            errorCallback,
            queryParams);
    }

    /* Servico - Validação */
    buscarPDFReceitaDispensada(requestData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/pdf'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    registrarDispensacao(requestData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/dispensacao'),
            requestData,
            successCallback,
            errorCallback
        );
    }

    validarReceita(requestData, successCallback, errorCallback) {
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/validar'),
            requestData,
            successCallback,
            errorCallback
        );
    }
}

const receitasClient = new ReceitasClient();
export default receitasClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};